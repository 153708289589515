html,
body {
    font-size: 20px;
    line-height: 1.5;
    .sans-serif;
}

body {
    background-color: #fff;
    position: relative;
    overflow-x: hidden; 
}

#content {
    background-color: #fff;
    .box-shadow(1px 1px 2px fade(#000, 10%));

    .no-background & {
	    padding-top: 102px;
    }

	.content-text {			   
		.container {
			.article-text {
		    	#k2Container {
		    		padding: 50px 0;
		    		margin: 0;

		    		.tmpl-gallery-page & {
		    			padding-bottom: 0!important;
		    		} 

					div.itemHeader {
						margin-bottom: 0px;

						h2.itemTitle {
							.serif;
							color: @blue;
							font-weight: bold;
						    font-size: 2.6em;
							opacity: 0;
							.transition;
							&.qnt 
							{
								opacity: 1;
							}
						}
					}

					div.itemBody,
					div.itemHeader {
						div.itemFullText {
							opacity: 0;
							.transition;
							&.qnt 
							{
								opacity: 1;
							}
							a.btn {
								margin-top: 50px;
							}
							a {
								color: @blue;
							}

							h3 {
								.sans-serif;
								font-size: 1.4em;
								font-weight: 300;
								padding: 0;
								margin-bottom: 15px;
								text-transform: uppercase;
							}

							h4 {
								.sans-serif;
								text-transform: uppercase;
								margin-top: 25px;
							}
						}
					}
		    	}
		    }
		}
	}
}