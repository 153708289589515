.booking {
	position: fixed;
	top: -100%;
	right: 0px;
	width: 300px;
	//padding: 0 15px 0px;
	padding: 0;
	z-index: 0;
	background-color: @lightgrey;
	border: 1px solid darken(@lightgrey, 10%);
	border-right: none;
	.transition(all ease-in-out 1s);

	.sb__calendar {
		border: 1px solid darken(@lightgrey, 10%);
		.border-radius(0 0 4px 4px);
	}

	@media all and (max-width: 1023px) {
		display: none;
	}

	&.open {
		top: 12px;
	}

	.incentivi-sb {
	    padding: 10px 15px 10px;

		h2 {
			color: @blue;
			.serif;
			font-size: 2.2em;
			font-weight: bold;
			text-align: right;
		    margin-top: 15px;
			@media all and (max-height:768px) {
				font-size: 1.3em;
				br {
					display: none;
				}
			}
		}

		ul {
			text-align: right;
			padding-left: 0;

			li {
				list-style-type: none;

				strong {
					color: @brown;
				}
			}
		}

		h3 {
			color: @blue;
			text-align: center;
			font-size: 1.1em;
		}
	}

	.sb {
		padding: 0;
	}

	.sb-screen-xs .sb__dates .sb__form-field {
		width: 100%;
	}

	.sb__form-field-label {
		display: none;
	}
	.sb__form-field {
		&.sb__form-field--checkin {
			/*margin-bottom: 16px;*/
		}
	}

	.sb__btn,
	.sb__form-field-input {
		.border-radius(0)!important;
	}

	.sb__btn {
		text-transform: uppercase;
	}

	.sb__form-field-input {
		color: @white;
	}

	.sb__form-field-icon .icon.sb-custom-icon-color use {
		color: @white!important;
		fill: @white!important;
	}
	a.sb__footer-link.sb__footer-link--edit.sb-custom-link-color.sb-custom-color-hover {
		/*display: none;*/
		margin-top: 3px;
		margin-bottom: 6px;
	}
	#sb-container {
		//padding-bottom: 15px;
		padding: 0px 15px;
		position: relative;
		z-index: 1;
		background-color: @lightgrey;
	}

	#chiudi-box {
		cursor: pointer;
		position: absolute;
		z-index: -1;
		background-color: @lightgrey;
		width: 48px;
		height: 35px;
		left: 50%;
		margin-left: -24px;
		bottom: 0px;
		.box-shadow(inset 0 40px 30px -50px @black);
		border: 1px solid darken(@lightgrey, 10%);
		.border-radius(0 0 24px 24px);
		.transition(all ease-in-out .3s);

		&:before {
			position: absolute;
			left: 50%;
			top: 50%;
			.transform(translate(-50%, -50%));
		}

		&.open {
			bottom: -36px;
		}
	}

	.swiper-container.swiper-container-widget {
		height: 150px;
	    z-index: 0;

		.swiper-wrapper {
			.swiper-slide 
			{
				text-align: center;
				img {
					width: 65%;
					margin-top: 10px;
				}				
			&.social{
					padding-top: 28px;
					img {
						width: 40px !important;
						z-index: 3;
						margin: 0;
						.transition(all ease-in-out .2s);
					}
					.text {
						font-size: 1.5em;
						/*margin-top: 10px;*/
						color: #2d84b0;
					}
					ul {
						padding: 0;
					}
					li {
						position: relative;
						display: inline-block !important;
						height: 40px;
						overflow: hidden;
						img {
							&:hover {
								margin-top: -40px;
							}
						}
					}					
				}
			}
		}
		.swiper-button {
			position: static;
			.swiper-button-next,.swiper-button-prev
			{
				background-color: white;
				top: 65px;
				&:before, &:after {
					color: #2d84b0;
					font-size: 0.5em;
				}
			}
		}
	}
	

	
	
}