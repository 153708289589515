/* -------------------------------------------------------------------------- */
/* --- TEMPLATE STANDARD ---------------------------------------------------- */
/* -------------------------------------------------------------------------- */

/* --- MIXIN ---------------------------------------------------------------- */
.box-shadow(@string) {
    box-shadow: @string ;
    -moz-box-shadow: @string;
    -webkit-box-shadow: @string;
}

.box-shadow(@string,@string1) {
    box-shadow: @string,@string1;
    -moz-box-shadow: @string,@string1;
    -webkit-box-shadow: @string,@string1;
}

.text-shadow(@string) {
    text-shadow: @string;
    -moz-text-shadow: @string;
    -webkit-text-shadow: @string;
}

.opacity(@alpha:0, @palpha:percentage(@alpha)){
    -webkit-opacity: @alpha;
    -moz-opacity: @alpha;
    opacity: @alpha;
    filter: alpha(opacity=@palpha); /* For IE8 and earlier */
    zoom: 1;
}

.transition(@string: all .3s ease-in-out) {
    transition: @string;
    -moz-transition: @string;
    -webkit-transition: @string;
}

.transform(@string) {
    transform:  @string;
    -moz-transform: @string;
    -webkit-transform: @string;
}

.border-radius(@radius) {
    border-radius: @radius;
    -moz-border-radius: @radius;
    -webkit-border-radius: @radius;
}

.keyframes(@name, @arguments) {
	@-moz-keyframes @name {@arguments();}
	@-webkit-keyframes @name {@arguments();}
	@keyframes @name { @arguments(); }
}

.animation(@arguments) {
	-webkit-animation: @arguments;
	-moz-animation: @arguments;
	animation: @arguments;
}

/* --- COMMON CLASS SITO BASE ----------------------------------------------- */
.background-cover {
  position: relative;
  height: 100%;
  outline: none;
  
  .image-cover {
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 0;
    }
}


.align-right {text-align: right;}
.align-left {text-align: left;}
.align-center {text-align: center!important;}

.absolute {position: absolute;}
.relative {position: relative;}
.fixed {position: fixed;}

.row.item {
    min-height: 200px;
    position: relative;
}


.no-alternate {
  .row {
    .background-cover {
        position: absolute;
        right: 0;
        width: 50%;
        height: 100%;
    }
  }
}


.alternate {
  .row {

    .background-cover {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
    }

        &.item:nth-of-type(2n+1) .background-cover {
          right: 0;
          left: auto;
        }
        &.item:nth-of-type(2n+2) .text {
            float: right;
        }
        &.item:nth-of-type(2n+2) .background-cover {
            left: 0;
        }
    }
}


.container-full {
    margin: 0px 5%;
    /*max-width: 1600px;*/

    .tmpl-faq & {
        margin: 0 auto;
        max-width: 1200px;
    }
}

/*
FLEX-WRAP--->whether items wrap to the next row (only applies if combined width of items is greater than container's)
-----------------------------------------------------------------------
JUSTIFY-CONTENT---> alignment along the x axis
-----------------------------------------------------------------------
ALIGN-ITEMS--->alignment along the y axis
-----------------------------------------------------------------------
ALIGN-CONTENT--->only applies if there is more than one row of items
*/
.flexbox(@flex-direction: row, @flex-wrap: nowrap, @justify-content: flex-start, @align-items: stretch, @align-content: stretch) {
    display: -moz-box;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: @flex-direction;
    -moz-flex-direction: @flex-direction;
    -ms-flex-direction: @flex-direction;
    flex-direction: @flex-direction;
    -webkit-flex-wrap: @flex-wrap;
    -moz-flex-wrap: @flex-wrap;
    -ms-flex-wrap: @flex-wrap;
    flex-wrap: @flex-wrap;
    -webkit-justify-content: @justify-content;
    -moz-justify-content: @justify-content;
    -ms-justify-content: @justify-content;
    justify-content: @justify-content;
    -webkit-align-items: @align-items;
    -moz-align-items: @align-items;
    -ms-align-items: @align-items;
    align-items: @align-items;
    -webkit-align-content:  @align-content;
    -moz-align-content:  @align-content;
    -ms-align-content:  @align-content;
    align-content:  @align-content;
}

.flexbox {
    .flexbox;
}

.flexbox-center-y {
    .flexbox(@align-items: center);
}

.flexbox-center-x {
    .flexbox(@justify-content: center);
}

.nopadding {
    padding: 0;
}

.marginTop {
    margin-top: 50px;
}
.marginBottom {
    margin-bottom: 50px;
}

.centerXY () {
    position: absolute;
    top: 50%;
    left: 50%;
    .transform(translate(-50%, -50%));
}
.centerX () {
    position: absolute;
    left: 50%;
    .transform(translateX(-50%));
}
.centerY () {
    position: absolute;
    top: 50%;
    .transform(translateY(-50%));
}

.centerXYrelative () {
    position: relative;
    top: 50%;
    left: 50%;
    .transform(translate(-50%, -50%));
}
.centerXrelative () {
    position: relative;
    left: 50%;
    .transform(translateX(-50%));
}
.centerYrelative () {
    position: relative;
    top: 50%;
    .transform(translateY(-50%));
}

/* -------------------------------------------------------------------------- */
/* --- END TEMPLATE STANDARD ------------------------------------------------ */
/* -------------------------------------------------------------------------- */

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,700|Playfair+Display:400,700');

@sans-serif : 'Josefin Sans', sans-serif;
@serif:  'Playfair Display', serif;

.sans-serif {
    font-family: @sans-serif;
}

.serif {
    font-family: @serif;
}

/* --- COLOR and BACKGROUND ------------------------------------------------- */
@white           : #ffffff;
@black           : #111111;
@blue            : #2d84b0;
@brown           : #b37c57;
@grey            : #797d7d;
@lightgrey       : #ebebeb;
@darkgrey        : #464646;

/* --- BACKGROUND ----------------------------------------------------------- */
.bg-white {
    color: @black;
    background-color: #fff;

    h2 {
        color: @blue;
    }

    h3 {
        color: @black;
    }

    b,
    strong {
        font-weight: bold;
    }
}

.bg-grey {
    color: @white;
    background-color: @grey;
}
.bg-darkgrey {
    color: @white;
    background-color: @darkgrey;
}
.bg-lightgrey {
    color: @black;
    background-color: @lightgrey;
}
.bg-black {
    color: @white;
    background-color: @black;
}
.bg-blue {
    color: @white;
    background-color: @blue;
}
.bg-brown {
    color: @white;
    background-color: @brown;
}


/* --- BUTTON --------------------------------------------------------------- */
.btn() {
    font-size: .8em;
    font-weight: 400;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 0 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 40px;
    line-height: 40px;
    .border-radius(0);
    .transition(all ease-in-out .3s);
    position: relative;
}

.btn-color(@background, @color: #fff) {
    color: @color;
    background-color: @background;
    border: 1px solid @background;
    .transition(all ease-in-out .3s);

    &:hover,
    &:focus {
        color: #fff;
        background-color: darken(@background, 12%);
        border: 1px solid darken(@background, 12%);
        .transition(all ease-in-out .3s);
    }

    &-border {
        color: @darkgrey;
        border: 1px solid @darkgrey;
        background: transparent;

        &:hover,
        &:focus {
            color: #fff;
            border: 1px solid @background;
            background: @background;

            & when (@background = @white) {
                background: transparent;
            }
        }
    }
}

.btn {
    .btn();

    &.btn-white {
        .btn-color(@white, @black);
        border: 1px solid @white;

        &:hover {
            .btn-color(transparent, @white);
            border: 1px solid @white!important;
            background: transparent;
            color: @white!important;
        }
    }

    &.btn-transparent {
        .btn-color(transparent);
        border: 1px solid @white;
        color: @white!important;

        &:hover
        &:focus  {
            .btn-color( @white);
            border: 1px solid @white!important;
            background: transparent;
            color: @blue!important;
        }
    }

    &.btn-blue {
        .btn-color(@blue, @white);
        color: @white!important;
        border: 1px solid @blue;

        &:hover {
            .btn-color(#eee, @blue);
            border: 1px solid @blue!important;
            background-color: @white!important;
            color: @blue!important;
        }
    }

    &.btn-brown {
        .btn-color(@brown, @white);
        color: @white!important;
        border: 1px solid @brown;

        &:hover {
            .btn-color(#eee, @brown);
            border: 1px solid @brown!important;
            background-color: @white!important;
            color: @brown!important;
        }
    }

    &.btn-darkgrey {
        .btn-color(@darkgrey, @white);
        color: @white!important;
        border: 1px solid @darkgrey;

        &:hover {
            .btn-color(#eee, @darkgrey);
            border: 1px solid @darkgrey!important;
            background-color: @white!important;
            color: @darkgrey!important;
        }
    }

    &.btn-big {
        padding: 0 75px;
        height: 60px;
        line-height: 58px;

        &:before {
            right: 20px!important;
        }
    }
}

.btn-white() {
    .btn();

    .btn-white {
        .btn-color(@white, @black);
        border: 1px solid @white;

        &:hover {
            .btn-color(transparent, @white);
            border: 1px solid @white!important;
            background: transparent;
            color: @white!important;
        }
    }
}

.btn-transparent() {
    .btn();

    .btn-transparent {
        .btn-color(transparent);
        border: 1px solid @white;
        color: @white!important;

        &:hover &:focus {
            .btn-color( @white);
            border: 1px solid @white!important;
            background: transparent;
            color: @blue!important;
        }
    }
} 

.btn-blue() {
    .btn();

    .btn-blue {
        .btn-color(@blue, @white);
        color: @white!important;
        border: 1px solid @blue;

        &:hover {
            .btn-color(#eee, @blue);
            border: 1px solid @blue!important;
            background-color: @white!important;
            color: @blue!important;
        }
    }
}

.btn-brown() {
    .btn();

    .btn-brown {
        .btn-color(@brown, @white);
        color: @white!important;
        border: 1px solid @brown;

        &:hover {
            .btn-color(#eee, @brown);
            border: 1px solid @brown!important;
            background-color: @white!important;
            color: @brown!important;
        }
    }
}


.btn-darkgrey() {
    .btn();

    .btn-darkgrey {
        .btn-color(@darkgrey, @white);
        color: @white!important;
        border: 1px solid @darkgrey;

        &:hover {
            .btn-color(#eee, @darkgrey);
            border: 1px solid @darkgrey!important;
            background-color: @white!important;
            color: @darkgrey!important;
        }
    }
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?taq712');
  src:  url('../fonts/icomoon.eot?taq712#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?taq712') format('truetype'),
    url('../fonts/icomoon.woff?taq712') format('woff'),
    url('../fonts/icomoon.svg?taq712#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bag:before {
  content: "\e934";
}
.icon-heart:before {
  content: "\e935"!important;
}
.icon-energy:before {
  content: "\e936";
}
.icon-water:before {
  content: "\e937";
}
.icon-recycle:before {
  content: "\e938";
}
.icon-arrow-down:before {
  content: "\e92d"!important;
}
.icon-clock:before {
  content: "\e92b";
}
.icon-minibar:before {
  content: "\e92c";
}
.icon-podio:before {
  content: "\e92a";
}
.icon-projector:before {
  content: "\e926";
}
.icon-microphone:before {
  content: "\e927";
}
.icon-notebook:before {
  content: "\e928";
}
.icon-flip-chart:before {
  content: "\e929";
}
.icon-meeting-school:before {
  content: "\e91a";
}
.icon-video:before {
  content: "\e91b"!important;
}
.icon-platea:before {
  content: "\e91e";
}
.icon-ferro-cavallo:before {
  content: "\e91f";
}
.icon-video2:before {
  content: "\e920";
}
.icon-bluetooth:before {
  content: "\e921";
}
.icon-meeting3:before {
  content: "\e922";
}
.icon-imperiale:before {
  content: "\e923";
}
.icon-school-desk:before {
  content: "\e924";
}
.icon-theater:before {
  content: "\e925";
}
.icon-google-plus:before {
  content: "\e918";
}
.icon-twitter:before {
  content: "\e919";
}
.icon-quotes-left:before {
  content: "\e900";
}
.icon-quotes-right:before {
  content: "\e901";
}
.icon-meeting:before {
  content: "\e902";
}
.icon-fitness:before {
  content: "\e903";
}
.icon-food:before {
  content: "\e904";
}
.icon-parked-car:before {
  content: "\e905";
}
.icon-wi-fi:before,
.icon-wifi:before {
  content: "\e906";
}
.icon-binoculars:before {
  content: "\e907";
}
.icon-coffee:before {
  content: "\e908";
}
.icon-check:before {
  content: "\e909";
}
.icon-arrow-up:before {
  content: "\e90a"!important;
}
.icon-size:before {
  content: "\e90b";
}
.icon-guest:before {
  content: "\e90c";
}
.icon-photo:before {
  content: "\e90d";
}
.icon-down-chevron:before {
  content: "\e90e";
}
.icon-calendar:before {
  content: "\e90f";
}
.icon-phone:before {
  content: "\e910";
}
.icon-envelope:before {
  content: "\e911";
}
.icon-plane:before {
  content: "\e912";
}
.icon-car:before {
  content: "\e913";
}
.icon-bed:before {
  content: "\e914";
}
.icon-discount:before {
  content: "\e915";
}
.icon-train:before {
  content: "\e916";
}
.icon-parking:before {
  content: "\e917";
}
.icon-left-arrow:before {
  content: "\e91c";
}
.icon-right-arrow:before {
  content: "\e91d";
}
.icon-linkedin:before {
  content: "\e92e";
}
.icon-instagram:before {
  content: "\e92f";
}
.icon-facebook:before {
  content: "\e930";
}
.icon-arrow-right:before {
  content: "\e931";
}
.icon-accessibility:before {
  content: "\e932";
}
.icon-pets:before {
  content: "\e933";
}