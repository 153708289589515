.moduleTitle {
	.serif;
	color: @blue;
	font-weight: bold;
	font-size: 2.6em;
	text-align: center;
}

.mappainterattiva  {
	&.mappaBusiness, &.mappaPosizione {
		.custom {
			background-color: #2d84b0;
			padding: 0;
		}
	tr.active > td {
		background-color: #b37c57;
		}
	a, a:active, a:hover {
		color: white;
		text-decoration: none;
		}
	}
	&.mappaPosizione {
	div {
		font-size: 17px;
		}	
	td {
		padding: 2px 8px !important;
		}
	}	
}

.poi-home {
	margin-bottom: 50px;
    .flexbox(row);

    .box-container {
        .flexbox(column);
        flex-wrap: wrap;
        padding: 0;

        .block {
            width: 25vw;
            height: 25vw;
            position: relative;

            @media all and (max-width: 1200px) {
            	width: 50vw;
            	height: 50vw;
            }

            .text {
                position: absolute;
                left: 0;
                width: 100%;
                height: 100%;
                color: @white;
                text-align: center;

                .wrap {
                	position: absolute;
                	left: 50%;
                	top: 50%;
            	    width: 80%;
                	.transform(translate(-50%, -50%));

                	h3 {
                		.serif;
                		font-style: italic;
                		font-size: 2.2vw; 
                	}
                	h2 {
                		.sans-serif;
                		font-size: 3vw;
                	}
                }
            }
        }

        &:nth-child(1) {
        	.block {
        		.text {
        			background-color: fade(@grey, 70%);
        		}
        	}
        }
        &:nth-child(2) {
        	flex-direction: column-reverse;

        	.block {
        		.text {
        			background-color: fade(@blue, 70%);
        		}
        	}
        }
        &:nth-child(3) {
        	.block {
        		.text {
        			background-color: fade(@brown, 70%);
        		}
        	}
        }
        &:nth-child(4) {
        	flex-direction: column-reverse;

        	.block {
        		.text {
        			background-color: fade(@black, 70%);
        		}
        	}
        }
    }
}

.list-offers {
	padding-left: 15px;
	padding-right: 15px;

	>h2 {
		.serif;
		color: @blue;
		font-weight: 700;
		font-size: 2.6em;
		text-align: center;
		margin-bottom: 50px;
		opacity: 0;
		.transition;
		&.qnt 
		{
			opacity: 1;
		}
	}
	.block {
		>span {
			.opacity(.5);
		}

		color: @white;
		height: 25vw;
		position: relative;
		border-bottom: 30px solid #fff;

		.text {
			padding: 20px;
			width: 85%;
			min-height: 15vw;
			position: absolute;
			top: 50%;
			left: 50%;
			.transform(translate(-50%, -50%));

			>h2 {
				text-align: center;
				font-size: 2.6vw; 
				.sans-serif;
				font-weight: lighter;
				margin-top: 0px;
				margin-bottom: 20px;
			}
			
			.btn {
				color: @white;
				text-transform: lowercase;
				.sans-serif;
				font-size: 1.6em;
				font-weight: lighter;
				height: 50px;
				line-height: 50px;
				float: right;
				margin-top: 50px;
			}
		}
/*
		&:nth-child(2) {
			background-color: @darkgrey;

			.text {
				.btn {
					background-color: @brown;
				}
			}
		}
		&:nth-child(3) {
			background-color: @blue;
			border-left: 15px solid @white;
			border-right: 15px solid @white;

			.text {
				.btn {
					background-color: @darkgrey;
				}
			}
		}
		&:nth-child(4) {
			background-color: @darkgrey;

			.text {
				.btn {
					background-color: @blue;
				}
			}
		}
*/		
		&:nth-child(odd) {
			background-color: @darkgrey;

			.text {
				.btn {
					background-color: @blue;
				}
			}
		}
		&:nth-child(even) {
			background-color: @blue;
			.text {
				.btn {
					background-color: @darkgrey;
				}
			}
		}
		&:nth-child(1n+2) {
			border-left: 15px solid @white;
			border-right: 15px solid @white;

		}		
		

	}
}

.servizi-home {
	background-color: @lightgrey;
	margin-top: 50px;
	padding-top: 50px;
	padding-bottom: 50px;

	>h2 {
		.serif;
		color: @blue;
		font-weight: 700;
		font-size: 2.6em;
		text-align: center;
	    margin-top: 0;
		margin-bottom: 50px;
		opacity: 0;
		.transition;
		&.qnt 
		{
			opacity: 1;
		}
	}

	.flexbox {
		.flexbox(row);
		/*justify-content: space-evenly;*/

		.box {
			.flexbox(column);
			text-align: center;

			span {
				width: 100px;
				height: 100px;
				.border-radius(100%);
				background-color: @darkgrey;
				color: @white;
				margin: 0 auto 30px;

				&:before {
					font-size: 3em;
					margin-right: 0;
					line-height: 100px;
				}
			}

			.text {
				text-align: center;
			    font-size: 1.3em;
		        line-height: 1.2em;
			}
		}
	}
}

.list-blog {
	padding-top: 50px;
	padding-bottom: 50px;
	position: relative;
	z-index: 0;

	&:before {
		position: absolute;
		z-index: 1;
		content: "";
		background-color: fade(@blue, 70%);
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	>h3 {
		.serif;
		color: @white;
		font-weight: 700;
		font-size: 2.6em;
		text-align: center;
		position: relative;
		z-index: 1;
		margin-top: 0;
	}
	>p {
		position: relative;
		z-index: 1;
		color: @white;
		text-align: center;
		margin-bottom: 50px; 
	}

	.k2ItemsBlock {
		position: relative;
		z-index: 2;
		width: 96%;
		margin: 0 auto;
		/*.flexbox(row);
		justify-content: center;*/

		.block {
			>span {
				.opacity(.5);
			}

			color: @white;
			height: 18vw;
			width: 32%;
			position: relative;
			background-color: @darkgrey;
			margin-bottom: 30px;

			&:nth-child(3n-1) {
				/*margin-left: 15px;
				margin-right: 15px;*/
				margin-left: 2%;
				margin-right: 2%;
			}

			.text {
				padding: 20px;
				width: 85%;
				min-height: 15vw;
				position: absolute;
				top: 50%;
				left: 50%;
				.transform(translate(-50%, -50%));

				>h2 {
					text-align: left;
					font-size: 2em; 
					line-height: 0.8em;
					.sans-serif;
					font-weight: lighter;
					margin-top: 0px;
					margin-bottom: 20px;
				}
				
				.btn {
					color: @white;
					background-color: @brown;
					text-transform: lowercase;
					.sans-serif;
					font-size: 1.6em;
					font-weight: lighter;
					height: 50px;
					line-height: 50px;
					float: right;
					margin-top: 50px;
				}
			}

			@media all and (max-width: 1023px) {
				width: 100%;

				&:nth-child(2) {
					margin-left: 0;
					margin-right: 0;
				}
			}

			@media all and (max-width: 370px) { 
				height: 80vmax!important;
			}
		}
	}

	&-hotel-sicuro {
		>p {
			width: 80%;
			margin: 0 auto 2em;
		}

		.k2ItemsBlock.swiper-container-blog-swiper {
			.swiper-wrapper {
				.swiper-slide.block {
					width: auto;
					height: 20vw;

					&:nth-child(3n-1) {
						margin-left: 0!important;
					}
				}
			}

			.swiper-button {
				width: 100%;
			    position: static;
			    top: auto;
			    margin-top: -60px;
			    right: 15px;
			    z-index: 1;

	        .swiper-button-prev,
	        .swiper-button-next {
	            margin-top: -10px;
	            @media all and (max-width:1360px) {
					margin-top: 10px;
	            }
	            top: 50%;
	            .transform(translateY(-50%));
	            background-color: rgba(255, 255, 255, 1);
	            &:hover {
	            	background-color: white;
	            }
	        }
	        .icon-right {
		             -moz-border-radius: 50%;
		             -webkit-border-radius: 50%;
		             border-radius: 50%;
	                right:5px;
	                z-index: 9;
	              &:before{
	                  content: "\e91d";
	                  width: 0;
	                  height: 0;
	                  border-style: solid;
	                  border-width: 15px 0 15px 15px;
	                  border-color: transparent transparent transparent #2d84b0;
	                  position: absolute;
	                  z-index: -1;
						top: 0;
						left: 20%;
						border: none;
						color: #b37c57;
	              }
	         }
	         .icon-left{
	             -moz-border-radius: 50%;
	             -webkit-border-radius: 50%;
	             border-radius: 50%;
	             border: none;
	             left:5px;
	             z-index: 9;

	             &:before{
	                 content: "\e91c";
	                 width: 0;
	                 height: 0;
	                 border-style: solid;
	                 border-width: 15px 15px 15px 0;
	                 border-color: transparent #2d84b0 transparent transparent;
	                 position: absolute;
				    top: 0;
				    left: 20%;
				    border: none;
				    color: #b37c57;
	             }
	         }
	       }
		}
	}

	.list-blog-hotel-sicuro .k2ItemsBlock .block:nth-child(3n-1) {
		margin-left: 0!important;
margin-right: 0!important;		
	}
}

.blocco-full {
	margin-top: 50px;
	margin-bottom: 50px;
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: @lightgrey;

	>span {
		.opacity(.2);
	}

	.intro {
		position: relative;
		z-index: 1;
		text-align: center;
		width: 50%;
		margin: 0 auto;
		opacity: 0;
		.transition;
		&.qnt 
		{
			opacity: 1;
		}
		>h2 {
			.serif;
			font-weight: 700;
			font-size: 2.6em;
			margin-top: 0;
		}
	}

	.flexbox {
		margin-top: 30px;
	    justify-content: center;
		
		.box {
			position: relative;
			z-index: 1;
			width: 40vw;
			height: 20vw;

			&:first-child {
				margin-right: 15px;
			}

			.text {

				h3 {
					font-size: 2.1em;
				    font-weight: lighter;

					strong {
						font-size: .5em;
					    font-weight: lighter;
					}
				}
				

				a.btn {
					color: @white;
					background-color: @darkgrey;
					text-transform: capitalize;
				    font-size: 1.6em;
				    height: 50px;
				    line-height: 50px;
				    font-weight: lighter;
				}
			}
		}
	}

	&.business {
		.flexbox {
			.box {
				.text {
					position: absolute;
					top: 50%;
					left: 50%;
					.transform(translate(-50%, -50%));
					width: 90%;
					opacity: 0;
					.transition;
					&.qnt 
					{
						opacity: 1;
					}
				}
			}
		}
	}

	&.meeting {
		background-color: @blue;

		.flexbox {
			.box {
				background-color: fade(@darkgrey, 70%);

				.text {
				    padding: 30px;
				}
			}
		}
	}
}

.review {
	margin-top: 50px;
	margin-bottom: 50px;
	opacity: 0;
	.transition;
	&.qnt 
	{
		opacity: 1;
	}
	.text {
		margin: 0 15%;

		blockquote {
			border-left: none;
			position: relative;
			text-align: center;

			&:after,
			&:before {
				font-family: "icomoon";
			    position: absolute;
			    color: @brown;
		        font-size: 3em;
				opacity: 0;
				.transition;
			}

			&:before {
			    content: "\e900";
			    
			    left: 0;
			    top: 0;
			}

			&:after {
			    content: "\e901";
			    right: 0;
			    bottom: 0;
			}
			&.qnt {
				&:after,
				&:before {
					opacity: 1;
				}
			}
			h2 {
				color: @blue;
				.serif;
				font-weight: 700;
				font-size: 2.6em;
				text-align: center;
				margin-top: 0;
				margin-bottom: 50px;
			}

			>p {
				font-size: 1.8em;
				.sans-serif;
				font-style: italic;
			}

			small {
				color: @black;
				.serif;
				font-size: 1.2em;

				&:before {
					display: none;
				}
			}
		}
	}
}

.list-room,
.list-meeting {

	>h2 {
		.serif;
		text-align: center;
		color: @blue;
		font-weight: bold;
	    font-size: 2.6em;
    	margin-bottom: 40px;
	}

    .k2ItemsBlock {
		/*margin-bottom: 50px;*/
		.flexbox(row);
		flex-wrap: wrap;

	    .box-container {
	    	.flexbox(row);
	        padding: 0;

	        .block {
	            width: 25vw;
	            height: 25vw;
	            position: relative;

	            @media all and (max-width: 1200px) {
	            	width: 50vw;
	            	height: 50vw;
	            }

	            .text {
	                position: absolute;
	                left: 0;
	                width: 100%;
	                height: 100%;
	                color: @white;
	                text-align: center;

	                .wrap {
	                	position: absolute;
	                	left: 50%;
	                	top: 50%;
	            	    width: 85%;
	                	.transform(translate(-50%, -50%));

	                	h3 {
	                		.serif;
	                		font-style: italic;
	            		    font-size: 1.6em;
							line-height: 1em;
							margin-top: 0px;
							margin-bottom: 30px;
	                	}
	                	h2 {
	                		.sans-serif;
	                		font-size: 2.5em;
	                	}

	                	a.btn {
							color: @white;
							text-transform: lowercase;
							.sans-serif;
							font-size: 1.6em;
							font-weight: lighter;
							height: 50px;
							line-height: 50px;
							margin-top: 25px;
	                	}
	                }
	            }
	        }

	        &:nth-child(1) {
	        	.block {
	        		.text {
	        			background-color: fade(@darkgrey, 100%);

	        			.wrap {
	        				a.btn {
	        					background-color: @brown;
	        				}
	        			}
	        		}
	        	}
	        }
	        &:nth-child(2) {
	        	.block {
	        		.text {
	        			background-color: fade(@blue, 100%);

	        			.wrap {
	        				a.btn {
	        					background-color: @darkgrey;
	        				}
	        			}
	        		}
	        	}
	        }
	        &:nth-child(3) {
	        	flex-direction: row-reverse;

	        	.block {
	        		.text {
	        			background-color: fade(@brown, 100%);

	        			.wrap {
	        				a.btn {
	        					background-color: @darkgrey;
	        				}
	        			}
	        		}
	        	}
	        }
	        &:nth-child(4) {
	        	flex-direction: row-reverse;

	        	.block {
	        		.text {
	        			background-color: fade(@darkgrey, 100%);

	        			.wrap {
	        				a.btn {
	        					background-color: @blue;
	        				}
	        			}
	        		}
	        	}
	        }
	        &:nth-child(5) {
	        	

	        	.block {
	        		.text {
	        			background-color: fade(@darkgrey, 100%);

	        			.wrap {
	        				a.btn {
	        					background-color: @brown;
	        				}
	        			}
	        		}
	        	}
	        }
	        &:nth-child(6) {

	        	.block {
	        		.text {
	        			background-color: fade(@blue, 100%);

	        			.wrap {
	        				a.btn {
	        					background-color: @darkgrey;
	        				}
	        			}
	        		}
	        	}
	        }
	        &:nth-child(7) {
	        	flex-direction: row-reverse;

	        	.block {
	        		.text {
	        			background-color: fade(@brown, 100%);

	        			.wrap {
	        				a.btn {
	        					background-color: @darkgrey;
	        				}
	        			}
	        		}
	        	}
	        }
	        &:nth-child(8) {
	        	flex-direction: row-reverse;

	        	.block {
	        		.text {
	        			background-color: fade(@darkgrey, 100%);

	        			.wrap {
	        				a.btn {
	        					background-color: @brown;
	        				}
	        			}
	        		}
	        	}
	        }
	    }
    }
}

.list-room {
	.k2ItemsBlock {
		.box-container {
			/*&:nth-child(5) {
	        	flex-direction: row-reverse;
	        	width: 100%;

	        	.block {
	        		.text {
	        			background-color: fade(@darkgrey, 100%);

	        			.wrap {
	        				a.btn {
	        					background-color: @brown;
	        				}
	        			}
	        		}

	        		&-img {
	        			width: 50vw;
	        		}
	        	}
	        }*/
			&.clsSuite {
	        	flex-direction: row;
	        	width: 100%;

	        	.block {
	        		.text {
	        			background-color: fade(@darkgrey, 100%);

	        			.wrap {
	        				a.btn {
	        					background-color: @brown;
	        				}
	        			}
	        		}

	        		&-img {
	        			width: 50vw;
	        		}
	        	}
	        }	        

/*	        &:nth-child(7) {
	        	flex-direction: row; 
	        }*/
		}
	}
} 

.free-kids {
	padding-top: 40px;
	padding-bottom: 40px;

	h3 {
		.sans-serif;
		font-size: 1.4em;
	}
}

/*gallery camere*/

.swiper-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
	
	&-gallery-rooms {
    	margin-top: 0px;
        margin-bottom: 50px;

        .mobile & {
            margin-bottom: 0;
        }

        height: 22vw;
        position: relative;
        padding: 0px 40px;

        @media all and (max-width: 1360px) {
            padding: 30px 45px;
        }
        @media all and (max-width: 769px) {
            padding: 20px;
            height: 80vmin;
        }

        .swiper-wrapper {
                .swiper-slide {
                    a {
                        display: block;

                        span {
                            .box-shadow(inset 0px 0px 12vw 12vw rgba(0,0,0,0.0););
                            .transition(all ease-in-out .3s);
                            height: 20vw;

                            &:before {
                            	.centerXY;
                            	color: @white;
                            	font-size: 5em;
                            	.opacity(0);
                            	.transition;
                            }
                        }

                        &:hover {
							span {
								&:before {
									.opacity(.75);
								}
							}
                        }
                    }
                }
        }


		.swiper-button {
			width: 100%;
		    position: static;
		    top: auto;
		    margin-top: -60px;
		    right: 15px;
		    z-index: 1;

		

        .swiper-button-prev,
        .swiper-button-next {
            margin-top: -10px;
            @media all and (max-width:1360px) {
				margin-top: 10px;
            }
            top: 50%;
            .transform(translateY(-50%));
            background-color: rgba(255, 255, 255, 1);
            &:hover {
            	background-color: white;
            }
        }
        .icon-right {
	             -moz-border-radius: 50%;
	             -webkit-border-radius: 50%;
	             border-radius: 50%;
                right:5px;
                z-index: 9;
              &:before{
                  content: "\e91d";
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 15px 0 15px 15px;
                  border-color: transparent transparent transparent #2d84b0;
                  position: absolute;
                  z-index: -1;
					top: 0;
					left: 20%;
					border: none;
					color: #b37c57;
              }
         }
         .icon-left{
             -moz-border-radius: 50%;
             -webkit-border-radius: 50%;
             border-radius: 50%;
             border: none;
             left:5px;
             z-index: 9;

             &:before{
                 content: "\e91c";
                 width: 0;
                 height: 0;
                 border-style: solid;
                 border-width: 15px 15px 15px 0;
                 border-color: transparent #2d84b0 transparent transparent;
                 position: absolute;
			    top: 0;
			    left: 20%;
			    border: none;
			    color: #b37c57;
             }
         }
       }
    } /* Gallery rooms*/
}

.service-room {
	.text {
		background-color: fade(@blue, 70%);
		color: @white;
		position: relative;

		.container-fluid {
			padding-left: 0;
			padding-right: 0;
			padding-top: 70px;
			padding-bottom: 70px;
			.flexbox(row);
			flex-wrap: wrap;
			justify-content: center;

			h2 {
				width: 100%;
				text-align: center;
				.serif;
			    font-size: 2.5em;
			    font-weight: bold;
				margin-top: 0px;
				margin-bottom: 50px;
			}

			ul {
				width: 48%;
				margin: 1%;
				background-color: fade(@darkgrey, 70%);
				padding: 70px 10px 70px 11vw;
			}
		}
	}

	&.green {
		&.sostenibilita {
			>span {
				background-size: cover!important;
				background-repeat: no-repeat!important;
			}

			.text {
				background-color: fade(@brown, 70%);
				
				.container-fluid {
					ul {
						padding: 70px;
						width: 70%;

						li {
							list-style-type: none;
							margin-top: 25px;

							>span,
							strong {
								font-size: 1.4em;
							}
						}
					}
				}
			}
		}

		&.differenza {
			>span {
				background-size: cover!important;
				background-repeat: no-repeat!important;
			}

			.text {
				.container-fluid {
					ul {
						padding: 70px;

						li {
							&:first-child {
								list-style-type: none;

								h3 {
									font-size: 1.6em;
									text-transform: uppercase;
								}
							}
						}
					}
				}
			}
		}
	}
}

/*cta*/

.calltoaction {
    text-align: center;
    padding: 10px 0;
    background-color: @lightgrey;

    p {
        margin: 30px -2px;
        display: inline-block;
        width: 30%;

        a {
        	.sans-serif;
            position: relative;
            overflow: hidden;
            .transition(all ease-in-out .3s);
            text-transform: lowercase;
            width: 100%;
            margin: 0;
            display: block;
            font-size: 1.2em;
            height: 54px;
            line-height: 54px;
            background-color: @brown;
            color: @white;
            border: 3px solid @brown;
            text-decoration: none;

            &:before {
                line-height: 54px;
                .transition(all ease-in-out .3s);
                position: absolute;
            }

            &:hover {
                border: 3px solid @brown;
                background-color: @lightgrey;
                color: @brown;
            }
        }

        &:first-child {
            

            a {
                .transition(all ease-in-out .3s);
                position: relative;
                left: 0;

                /*&:before {
                	font-family: "icomoon";
                	content: "\e91c";
                    left: 25px;
                    line-height: 58px;
                    .transition(all ease-in-out .3s);
                }*/

                &:hover {
                    &:before {
                        left: 15px;
                    }
                }
            }
        }

        &:nth-child(2) {
        	margin-left: 75px;

            a {
                .transition(all ease-in-out .3s);
                position: relative;
                right: 0;

                &:hover {
                    &:before {
                        right: 15px;
                    }
                }
            }
        }
	}
}



/*.list-service {
	.item 
	{
		//height: 20vw;
		margin: 1%;
		padding: 0; 


		
		@media (min-width: 992px) 
		{
			&.col-md-12 
			{
				width: 48% !important;
			}	
			&:nth-child(1) 
			{
					width: 98% !important;
			}    

		}
		@media (min-width: 1200px)
		{
			&.col-lg-8 {
				width: 31.333333% !important;
			}			
			
			&:nth-child(1), &:nth-child(2) {
				width: 48%  !important;
			}  

			&:nth-child(1) .wrap-text, &:nth-child(2) .wrap-text {
				height: 350px !important;
			}  


			
		}		

		.text {
			//position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			padding: 50px;
			background-color: #464646;
			color: white;
			h3{
				font-size: 2.2em;
				color: white !important;
			}

			a.btn {
				background-color: #b37c57;
				color: white;
				position: absolute;
				bottom: 50px;
				right: 50px;
			}			
			
		}
		.wrap-text {
			background-size: cover;
		}
		&:nth-child(1) .text, &:nth-child(4) .text, &:nth-child(6) .text, &:nth-child(10) .text, &:nth-child(11) .text {
			background-color: rgba(45, 132, 176, .7);
		}		
		&:nth-child(2) .text, &:nth-child(9) {
			background-color: rgba(179, 124, 87, .7);
			a.btn {
				background-color: rgb(45, 132, 176);
			}	
		}				
	}
}*/

.list-block {

    .mobile & {
        > p {
            margin-right: 5%;
            margin-left: 5%;
        }
    }

    h5 {
        font-size: 30px;
        color: @brown;
        font-weight: lighter;
        margin-bottom: 15px;
        line-height: 60px;
        width: auto;
        text-transform: uppercase;

        .mobile & {
            text-align: center;
            font-size: 1.6em;


        }
    }

    &-lifestyle {
        #accordion,
        #accordion2 {
            margin-top: 0px;

            h3 {
                outline: none;
                border: 1px solid fade(@brown, 20%);
                font-size: 1em;
                padding: 15px 10px;
                margin: 15px auto;
                position: relative;
                .transition(all ease-in-out .3s);
                color: fade(@brown, 70%);

                &:before {
                    content: "\51";
                    font-family: "icomoon";
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    line-height: 0;
                    .transform(translateY(-50%));
                    vertical-align: middle;
                    font-size: 1em;
                    .transition(all ease-in-out .3s);
                }

                &:hover {
                    border: 1px solid fade(@brown, 100%);
                }

                &.ui-state-active {
                    border: 1px solid fade(@brown, 100%);
                    border-bottom: 1px solid fade(@brown, 20%);

                    &:before {
                        .transform(rotate(180deg));
                    }
                }
            }

            div {
                padding: 30px;
                background-color: transparent;
                margin-top: -15px;
                border: 1px solid darken(@blue, 40%);
                border-top: none;

                ul {
                    text-align: left;
                    margin: 20px auto;

                    li {
                        position: relative;
                        padding-left: 20px;

                        &:before {
                            content: "\48";
                            font-size: .8em;
                            font-family: "icomoon";
                            position: absolute;
                            left: 0;
                            top: 50%;
                            line-height: 0;
                        }
                    }
                }
            }
        }

        > ul {
            > li {
                min-height: 400px;
                background-color: @blue;
                .transition(all ease-in-out .3s);

                &:hover {
                    background-color: @brown;

                    *,
                    .block h3,
                    .block a {
                        color: #fff;
                    }
                    .block a.btn {
                        color: #fff;
                        background-color: @blue;
                    }
                }
                .background-cover {
                    background-position: 50%;
                }
                .block {
                    .transition(all ease-in-out .3s);
                    cursor: pointer;

                    h3 {
                        line-height: 1!important;
                        margin-bottom: 20px;
                        margin-top: 0px;
                    }
                    a {
                        color: @brown;
                    }
                }
            }
        }
    }

    &-lifestyle {
        .grid {
            @media all and (max-width: 769px) {
                .tablet & {
                    .grid-sizer,
                    .grid-item {
                        width: 50%;
                    }
                }
            }
            .grid-sizer {
                width: 33.333%;
                /*margin-top: 20px;*/
            }
            .grid-item {

                width: 33.333%;
                padding: .333vw;

                &-inside {
                    padding: 15px;
                    border-top: 5px solid;
                    border-bottom: 5px solid ;
                    border-right: 5px solid;
                    border-left: 5px solid;
                    border-color: @white;
                    background-color: @blue;
                    .transition(all ease-in-out .3s);
                    a{
                        color:@blue;
                    }

                    ul {
                        padding: 0;
                        li {
                            position: relative;
                            padding-left: 20px;

                            &:before {
                                content: "\48";
                                font-size: .8em;
                                font-family: "icomoon";
                                position: absolute;
                                left: 0;
                                top: 2px;
                                // line-height: 1em;
                            }
                        }
                    }
                }

                &:hover {
                    .grid-item-inside {
                        background-color: darken(@blue, 10%);
                    }
                }

                &:nth-child(odd) {
                    .grid-item-inside{
                    	background-color: @darkgrey;
                        display:flex;
                        flex-direction:column-reverse;
                    }

                    &:hover {
	                    .grid-item-inside {
	                        background-color: lighten(@darkgrey, 10%);
	                    }
            		}
                }

                .background-cover {
                    height: 240px!important;
                    background-size: cover;
                }
                .block {
                    text-align: left;
                    color: @white;
                    padding: 25px;

                    h3 {
                        font-size: 1.6em;
                        position: relative;
                        cursor: pointer;
                        .transition(all ease-in-out .3s);

                        &:hover {
                            color: @blue;
                        }

                        &:before {
                            font-family: "icomoon";
                            content: "\3a";
                            font-size: 1em;
                            position: absolute;
                            line-height: 0;
                            left: 0;
                            top: 50%;
                        }
                    }
                    h4 {
                        font-size: 1em;
                        margin-top: 0;
                    }
                }
            }
        }

        &.servizi {
            .grid .grid-item .grid-item-inside .block h3 {
                pointer-events: none;

                &:before {
                    content: "";
                }

                span:before {
                    font-size: 1em;
                    position: absolute;
                    line-height: 0;
                    left: 0;
                    top: 50%;
                }
            }
        }
    }

    > ul {
        padding-left: 0;

        > li {
            margin: 70px auto!important;
            display: -moz-box;
            display: -webkit-flexbox;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: -moz-flex;
            display: flex!important;
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;

            @media all and (max-width: 769px) {
                .tablet & {
                    -webkit-flex-direction: column;
                    -moz-flex-direction: column;
                    -ms-flex-direction: column;
                    flex-direction: column;
                }
            }

            &:first-child {
                margin: 0px auto!important;
            }

            // &.color-list:last-child {
            //     margin-bottom: 0!important;
            // }

            .background-cover {
                height: auto;
                background-size: cover;

                @media all and (max-width: 769px) {
                    .tablet & {
                        height: 320px;
                    }
                }
            }

            .block {
                padding: 100px 30px;
                color: @brown;

                @media all and (max-width: 1025px) {
                    padding: 50px 30px;
                }

                @media all and (max-width: 769px) {
                    .tablet & {
                        padding: 30px;
                    }
                }

                &:hover {
                    * {
                        color: #fff;
                    }
                    a.btn {
                        color: #fff;
                        background-color: @blue;
                    }
                }

                h3 {
                    color: @brown;
                    font-size: 2.2em;
                    line-height: 1;
                    text-transform: uppercase;
                }

                ul {

                    li {
                        display: inline-block;
                        position: relative;
                        margin: 0;

                        &:first-child {
                            margin-right: 15px;
                            &:after {
                                content: "-";
                                position: absolute;
                                right: -13px;
                                top: 0;
                            }
                        }

                        &:last-child {
                            display: none;
                        }

                        span {
                            &:last-child {
                                margin-right: 5px;
                            }

                            &:before {
                                vertical-align: middle;
                                bottom: 2px;
                                font-size: 1.2em;
                                position: relative;
                            }
                        }
                    }
                }

                .moduleItemIntrotext {
                    margin-bottom: 30px;
                }

                .moduleItemExtraFields {
                    display: inline-block;
                    margin: 40px auto 15px;
                    font-weight: bold;
                }
            }
        }

    }

    &.mobile {
        h3 {
            margin-top: 0;
            margin-bottom: 0;
            padding: 5px 10px;
            padding-right: 1em;
            outline-color: transparent;
            position: relative;
            text-transform: uppercase;
            .transition(all ease-in-out .3s);

            &:before {
                position: absolute;
                top: 50%;
                line-height: 0;
                right: 15px;
                font-family: "icomoon";
                content: "\51";
                .transition(transform ease-in-out .3s);
            }

            &.ui-state-active {
                &:before {
                    color: #fff;
                    .transform(rotate(540deg));
                }
            }
        }

        div {
            padding: 20px 10px;

            > span {
                display: inline-block;
                position: relative;

                p {
                    margin-bottom: 0;
                }

                &:first-child {
                    margin-right: 20px;

                    &:after {
                        content: "-";
                        position: absolute;
                        top: 10px;
                        right: -15px;
                        line-height: 0;
                    }
                }
            }
        }
    }
}

.blocchi-testo {

	&.ristorante {
		.block:last-child .text {
			width: 60vw;
		}
	}

	&.bg-order-reverse {
		.block {
			background-color: @blue;
			
			&:nth-child(even) {
				background-color: @darkgrey;
			}
		}
	}

	&.block-order-reverse {
		.block {
			flex-direction: row-reverse;
		}
	}

	&.bg-contain {
		.block {

			.background-cover {
				background-color: @white;	

				span {
					background-size: contain;
				    background-repeat: no-repeat;
				}
			}
		}
	}

	&.blocchi-testo-overlay {
		.block {
			.background-cover {
				.flexbox;
				background-color: @darkgrey;

				>span {
					.opacity(.5)!important;
				}

				.text {
					.flexbox(column);
					align-self: center;
				}
			}

			&:nth-child(even) {
				background-color: @darkgrey;

				.text {
					.btn {
						border: 1px solid @blue;
						background-color: @blue;
					}
				}
			}
		}
	}

	.block {
		.flexbox;
		flex-wrap: wrap;
		background-color: @darkgrey;

		&:nth-child(even) {
			flex-direction: row-reverse;
			background-color: @blue;

			.text {
				a.btn {
					border:1px solid @darkgrey;
					background-color: @darkgrey;
					color: @white;

					&:hover {
						border:1px solid @white;
						color: @white;
						background-color: @blue; 
					}
				} 
			}
		}

		.background-cover {
			min-height: 25vw;
			height: auto;
		}

		.text {
			padding-top: 30px;
			padding-bottom: 30px;
		    align-self: center;
			width: 35vw;
			margin: 0 auto;
			color: @white;

			a.btn {
				border:1px solid @blue;
				background-color: @blue;
				color: @white;

				&:hover {
					color: @blue;
					background-color: @darkgrey; 
				}
			} 
		}
	}

	&.no-img {
		.flexbox;

		.block {
			background-color: @darkgrey;

			.text {
				width: 50vw;
				padding-left: 70px;
				padding-right: 70px;

				ul {
					list-style-type: none;
					padding-left: 0;
				}
			}
		}
	}
}

.blocchi-video {

	.block {
		.flexbox;
		flex-wrap: wrap;
		background-color: @darkgrey;
		min-height: 27.5vw;

		&:nth-child(even) {
			flex-direction: row-reverse;
			background-color: @blue;

			.text {
				a.btn {
					border:1px solid @darkgrey;
					background-color: @darkgrey;
					color: @white;

					&:hover {
						border:1px solid @white;
						color: @white;
						background-color: @blue; 
					}
				} 
			}
		}

		.background-cover {
			min-height: 25vw;
			height: auto;
		}

		.text {
			padding-top: 30px;
			padding-bottom: 30px;
		    align-self: center;
			width: 35vw;
			margin: 0 auto;
			color: @white;

			a.btn {
				border:1px solid @blue;
				background-color: @blue;
				color: @white;

				&:hover {
					color: @blue;
					background-color: @darkgrey; 
				}
			} 
		}

		.video {
			position: relative;
			overflow: hidden;
			padding: 0;

			video {
				width: 102%;
				height: 100%;
				min-width: 100%;
				min-height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}

/*mappa interattiva*/
.googlemap {
	>p {
		margin: 0;
	}

    .phocamaps {
        background-color: @darkgrey;

        #phocamaps-box {
            margin-top: 0!important;
            float: left;
            margin: 0;
            width: 70%;

            @media all and (max-width: 769px) {
                width: 100%;
            }
        }

        .pmroute {
            margin-left: 0;
            margin-top: 0;
            padding: 20px 30px;
            color: @white;
            width: 30%;
            float: left;
            clear: none;
            height: 500px;
            text-align: center;
            background-color: @darkgrey;

            @media all and (max-width: 769px) {
                width: 100%;
                display: block;
            }

            @media all and (max-width: 769px) {
                height: 250px;
            }

            > .form-inline {
                padding-top: 25%;
                vertical-align: middle;
                font-size: 1.4em;

                @media all and (max-width: 769px) {
                    display: block;
                    padding-top: 0;
                }
            }

            form {
                .pm-input-route {
                    background-color: darken(@darkgrey, 5%);
                    outline: none;
                    border: none;
                    border-bottom: 3px solid darken(@grey, 2%);
                    height: 40px;
                    vertical-align: top;
                    color: @white;
                    padding: 5px;
                    display: block;
                    margin: 10px 10%;
                    width: 80%;
                    border-radius: 0px;
                    font-size: .7em;

                    @media all and (max-width: 1023px) {
                        width: 90%;
                        margin: 10px 5%;
                    }

                    @media all and (max-width: 769px) {
                        margin: 10px auto;
                    }

                    .mobile & {
                        width: 100%;
                    }
                }

                .pm-input-route-btn {
                    color: @white;
                    border: 3px solid @blue;
                    background: @blue;
                    width: 80%;
                    font-size: .7em;

                    @media all and (max-width: 1200px) {
                        font-size: .5em;
                    }
                    @media all and (max-width: 1023px) {
                        width: 90%;
                    }

                        &:hover {
                            color: @white;
                            background-color: @darkgrey;
                        }

                    .mobile & {
                        width: 100%;
                    }
                }
            }
        }

        #phocaDirPlgPM1 {
            background-color: @white;
            border-top: darken(@grey, 30%);
            div {
                div {
                    width: 80%;
                    margin: 0 10%;

                    .mobile &,
                    .tablet & {
                        @media all and (max-width: 769px) {
                            width: 95%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}
#phocamaps-box div.pmbox-grey div div div {
    padding: 0 !important;
}

/*come raggiungerci*/
.how-reach-us {
    margin-bottom: 70px;

    @media all and (max-width: 769px) {
        .flexbox(column);
    }

    >.tab-control {
        margin: 0;
        padding-left: 0;
        padding-right: 0;

        ul.nav.nav-stacked {
            height: 100%;
			right: -1px;
			position: relative;
			z-index: 1;

            li {
            	position: relative;
                margin-top: 0;
                height: 70px;

                @media all and (max-width: 769px) {
                    height: 50px;
                    width: 100%;
                }

                a {
                    background-color: @blue;
                    color: @white;
                    position: relative;
                    height: 100%;
                    line-height: 70px;
                    overflow: hidden;
                    border-bottom: solid 1px fade(@white, 50%);
                    padding-top: 0;
                    padding-bottom: 0;
                    font-size: 1.6em;
                    text-transform: lowercase;
    				font-weight: 300;

                    span {
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        .transform(translateY(-50%));
                        text-transform: uppercase;
                    }

                    &:hover {
                        background-color: darken(@blue, 5%);
                    }

                }

                &.active {
                    a {
                        color: @blue;
                        background-color: white;
                        border-left: 1px solid fade(@blue, 30%);
                    }
                }

                &:first-child {
                	a {
                		border-top: 1px solid fade(@blue, 30%);
                	}
                }

                &:last-child {
                    a {
                        border-bottom: 1px solid fade(@blue, 30%);
                    }
                }
            }
        }
    }




    >.tab-content {
        padding-left: 30px;
        .flexbox;
        align-items: center;
        /*.box-shadow(inset 0 0 1px @blue);*/
        border: 1px solid fade(@blue, 30%);

        .tab-pane {
            padding: 30px;
            position: relative;
            overflow: hidden;
            min-height: 250px;

            h3 {
                text-transform: uppercase;
                color: @blue;
            }

            a {
                color: @darkgrey;
            }

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                .transform(translate(-50%, -50%));
                font-size: 12em;
                color: fade(@lightgrey, 70%);
                z-index: -1;
            }

            >.tab-control {
				ul.nav.nav-stacked {
					li {
						display: inline;

						a {
							color: @blue;
							display: inline-block;
							.btn();
							border: 1px solid @blue;

							&:hover {
								background-color: @blue;
								color: @white;
							}
						}

						&.active {
							a {
								background-color: @blue;
								color: @white;
							}
						}
					}
				}
            }
        }
    }
}


/*-----mappa dove siamo-------------------------------------------------------*/

.marker-link div span:last-child {
    float: right;
    margin-right: 20px;
}

.marker-link div span:first-child {
    margin-left: 20px;
}

.mappainterattiva {
    padding: 0;
    /*margin-bottom: 15px;*/
    height: 550px;

    @media all and (max-width: 769px) {
        height: auto;
        .flexbox(column);
    }

    @media all and (max-width: 769px) {
        .tablet & {
            display: block;
            height: auto;
        }
    }

    #interactive-map {
        padding: 0;

        @media all and (max-width: 769px) {
            .tablet & {
                display: block;
                height: 450px;
            }
        }

        #map {
            // min-height: 500px;
            // height: 100%;
        }
        @media all and (max-width: 769px) {
            display: block;
            //height: 350px;
            min-height: none;
        }
    }

    #interactive-map #map {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%
    }

@media all and (max-width: 767px) {
    #interactive-map #map {
        left:0;
        position: relative;
        height: 250px;
        top: 0;
        width: 100%
    }
}

#interactive-map #map .marker {
    text-align: center;
    transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear
}

#interactive-map #map .marker:before {
    position: relative;
    z-index: 2;
    top: 0;
    width: 60px;
    height: 60px;
    display: block;
}

#interactive-map #map .marker#hotel-marker:before {
    content: "";
    background-image: url(/images/marker/logo-hotel-marker.png);
    z-index: 3;
    width: 88px;
    height: 108px;
}

#interactive-map #map .marker#Stazione-marker:before,
#interactive-map #map .marker#FirenzeFiera-marker:before,
#interactive-map #map .marker#StazioneLeopolda-marker:before,
#interactive-map #map .marker#Fortezza-marker:before,
#interactive-map #map .marker#Aeroporto-marker:before,
#interactive-map #map .marker#AeroportoPisa-marker:before,
#interactive-map #map .marker#AeroportoBologna-marker:before,
#interactive-map #map .marker#Cenacolo-marker:before,
#interactive-map #map .marker#SantaMariaNovella-marker:before,
#interactive-map #map .marker#Oltrarno-marker:before,
#interactive-map #map .marker#BarberinoOutlet-marker:before,
#interactive-map #map .marker#Tornabuoni-marker:before,
#interactive-map #map .marker#Signoria-marker:before,
#interactive-map #map .marker#Uffizi-marker:before,
#interactive-map #map .marker#PonteVecchio-marker:before,
#interactive-map #map .marker#Duomo-marker:before,
#interactive-map #map .marker#Cascine-marker:before,
#interactive-map #map .marker#SantaMariaFiore-marker:before,
#interactive-map #map .marker#Opera-marker:before,
#interactive-map #map .marker#PalazzoStrozzi-marker:before

{
    content: "";
    background-image: url('/images/marker/hotel-POI.png');
    position: relative;
    z-index: 2;
    top: 0;
    width: 50px;
    height: 50px;
    display: block;
}

#interactive-map #map .marker.active:before {
    top: 0;
    background-image: url('/images/marker/hotel-POI-active.png')!important;
    z-index: 4;
    width: 50px!important;
    height: 50px !important;
}

#interactive-map .marker-poi {
    position: relative;
    width: 20%;
    z-index: 2;
    color: #fff;
    background-color: #4a3131;
    padding: 0;
    box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -moz-box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -webkit-box-shadow: 0 0 24px rgba(74,49,49,0.5)
}

@media all and (max-width: 1200px) {
    #interactive-map .marker-poi {
        width:40%
    }
}

#interactive-map .marker-poi .custom {
    height: 100%
}

#interactive-map .marker-poi .marker-link {
    margin: 0
}

#interactive-map .marker-poi .marker-link a {
    position: relative;
    z-index: 1;
    font-size: 1rem;
    font-weight: 100;
    color: #fff;
    display: block;
    padding: 15px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear
}

#interactive-map .marker-poi .marker-link a:before {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -9px
}

#interactive-map .marker-poi .marker-link a strong {
    display: block;
    font-size: .9em;
    text-transform: uppercase
}

#interactive-map .marker-poi .marker-link a:hover,#interactive-map .marker-poi .marker-link a:focus {
    text-decoration: none;
    // background-color: rgba(208,70,68,0.5)
    background-color: fade(@grey, 80%);
}

#interactive-map .marker-poi .marker-link.active a {
    background-color: fade(@grey, 80%);
    z-index: 2;
    box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -moz-box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -webkit-box-shadow: 0 0 24px rgba(74,49,49,0.5)
}

#interactive-map .marker-poi .marker-link.active a:before {
    color: #4a3131
}

#interactive-map .marker-poi .marker-link:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    right: -100%;
    top: 0;
    background-position: center center;
    background-size: cover;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    zoom:1;transition: all .6s linear;
    -moz-transition: all .6s linear;
    -webkit-transition: all .6s linear;
    -o-transition: all .6s linear;
    border-right: 5px solid #d04644
}

@media all and (max-width: 1200px) {
    #interactive-map .marker-poi .marker-link:after {
        display:none
    }
}

#interactive-map .marker-poi .marker-link.active:after {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    // filter: alpha(opacity=100%);
    zoom:1;
    }

    @media all and (max-width: 680px) {
        #interactive-map .marker-poi {
            width:100%
        }

        #interactive-map .marker-poi .marker-link {
            display: none;
            text-align: center
        }

        #interactive-map .marker-poi .marker-link.active {
            display: block
        }

        #interactive-map .marker-poi .marker-link.active a:after {
            position: absolute;
            bottom: -15px;
            left: 50%;
            margin-left: -7.5px;
            content: "";
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 20px solid #d04644
        }

        #interactive-map .marker-poi .marker-link.active a:before {
            display: none
        }
    }
}

.poi {
    background-color: @darkgrey;
    color: @white;

    @media all and (max-width: 769px) {
        .tablet & {
            display: block;
            padding: 20px 0 0;
        }
    }

    > span {
        .opacity(.5);
        .transition(all ease-in-out .3s);
    }

    h3 {
        line-height: 1;
    }

    > span {
            position: relative;
            z-index: 0;
    }

    .container-poi {
        position: relative;
        z-index: 1;


        h3 {
            .serif;
            text-align: center;
            font-size: 1.8em;
            width: 90%;
            margin: 20px auto;

            @media all and (max-width: 767px) {
                width: 90%;
                font-size: 1.6em;
            }
        }

        .table {
            margin: 0px auto;

            tr {
                border-bottom: 1px solid fade(#fff, 20%);

                &.marker-link.active {
                    border-bottom: 1px solid fade(#111, 10%);

                    td {
                        background-color: fade(@blue, 100%);

                        div a {
                            color: @white;
                        }
                    }
                }

                &:last-child {
                        border-bottom: none;
                }

                td {
                    border: none;
                    padding: 6px 20px;
                    line-height: 1.5;

                    div {
                        a {
                            color: inherit;
                            text-decoration: none;

                            span {
                                display: inline;
                                width: 60%;
                                float: left;

                                &:last-child {
                                    float: right;
                                    width: 40%;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.gallery-wrapper {
	.row {
		margin: 75px 0;

		&:first-child {
			margin-top: 0;
		}

		h3 {
			margin-top: 50px;
			margin-bottom: 30px;
			margin-left: 30px;
			text-transform: uppercase;
			font-size: 1.6em;
			color: @darkgrey;
		}
	}
}

.gallery-page {

    @media all and (max-width: 769px) {
        margin-top: -40px;

        .tablet & {
            margin-top: 70px;
        }
    }

    .background-cover {
        padding: 0;
        background-color: @darkgrey;
        overflow: hidden;
        position: relative;
        outline: none;

         >span {
            .transition(all ease-in-out 1s);
            position: relative;

            &:after {
                font-family: "icomoon";
                content: "\e90d";
                font-size: 5em;
                color: fade(@white, 70%);
                background: transparent;
                .opacity(0);
                position: absolute;
                z-index: 1;
                left: 50%;
                bottom: -50%;
                cursor: pointer;
                .transform(translate(-50%, 50%));
                .transition(all ease-in-out .3s);
                // text-shadow: 1px 1px 1px fade(@black, 50%), 0 0 40px fade(@black, 50%);

                .mobile &,
                .tablet & {
                    bottom: 50%;
                    .opacity(0);
                }
            }
        }


        &:hover {
             >span {

                &:after {
                    bottom: 50%;
                    .opacity(1);
                }
            }
        }

        .image-cover {
            position: relative;
            z-index: 1;
            left: 0;
            top: 0;
            height: 15vw;
            float: left;
            background-size: cover;
            background-position: center center;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            overflow: hidden;

            @media all and (max-width: 1600px) {
            	height: 25vw;
            }

            @media all and (max-width: 1200px) {
            	height: 32vw;
            }

            .mobile & {
                height: 45vmax;
            }

            &:before {
                background-color: #fff;
            }
        }

    }
}

/*dettaglio blog*/
.content-text.blog {
    background-color: white !important;
}
.sidebarBlog div.k2ItemsBlock {
    /*border-left: solid 1px #dddddd;*/

    .container-full {
        ul {
            li.clearList,
            li:nth-last-child(2) {
                border-bottom: none;
            }
        }
    }
}

.sidebarBlog.col-sm-8 {
    margin-top: 40px;    
    margin-bottom: 40px;    
    background-color: @lightgrey;
    top: 150px;
    position: sticky;
    position: -webkit-sticky;

	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 	
    	position: relative!important;
    	top: auto!important;
    }

    .k2ItemsBlock {
    	padding-top: 20px;
    	padding-bottom: 20px;
    }
}
.addthis_inline_share_toolbox {
    text-align: left;
}
.moduleTitle {
    .serif;
    color: @blue;
    font-weight: 300;
    font-size: 2em;
    text-align: left;
}

.dett-blog{
    .itemHeader{
        h3{
            .serif;
            color: @blue;
            font-weight: 300;
            font-size: 2em;
            text-align: left;
        }
    }

    .articolo-blog{
        .itemExtraFields{
            border:none;
                .vantaggi{
                    color:@blue;
                    font-family: @sans-serif;
                    padding:0;
                    }
        }

       .text{
            margin-top: 11vw;

       }
        .testo-articolo-blog{
            text-align: left;
            /*margin-top: 30px;*/
            height:100%;

                h3 {
                z-index: 1;
                position: relative;
                font-family: @sans-serif;
                font-weight: 100;
                text-align: center;
                font-size: 30px;
                font-weight: bold;
                text-align: left;
                padding: 40px;
                margin-bottom: 1.5vw;
                 }
             p {
                padding: 0 40px;
                em{

                }

             }
             .btn{
                top: 10vw;
                z-index: 1;
                position: absolute;

             }
             .itemFullText{
                margin-top: 30px;
             }
        }
        .background-cover{
            min-height: 25vw;
            /*margin-top: 30px;*/
            margin-bottom: 30px;
        }

    }

}

.sidebarBlog .moduleTitle {
	margin-bottom: 20px;
}

.sidebarBlog .background-cover {
    height: 6vw;
    width: 6vw;
    float: left;
    margin-right: 20px;
    margin-bottom: 15px;
}

.sidebarBlog li p {
    clear: both;
}

.sidebarBlog h2 {
    color: @darkgrey;
    font-size: 1.5em;
    margin-top: 0;
}

.sidebarBlog ul li {
    cursor: pointer;
    padding: 20px;
    .transition;

    &:hover {
    	background-color: darken(@lightgrey, 5%);
    }
}

/*dettaglio offerte*/
.dett-offers {
	.itemBody {

		.itemHeader {
			margin-top: 30px;
			margin-bottom: 30px;

			.itemTitle {
				.serif;
				color: @blue;
				font-weight: 300;
				font-size: 2em;
				text-align: center;
				margin: 0
			}
		}
		.itemImageBlock {
			height: 20vw;
	        margin: 40px 0 40px 0px;
		    padding: 0;
		}
		.itemFullText {
			text-align: left;

			h3 {
				color: #2d84b0;
				font-size: 1.6em;
				margin-top: 30px;
				font-weight: 400;
			}

			em {
				background-color: @lightgrey;
				display: block;
				padding: 20px;
			}

			ul {
				list-style-type: none;
			    padding-left: 30px;

				li {
					&:before {
						color: @brown;
						content: "\e909";
						font-family: "icomoon";
						position: absolute;
						left: 0;
						font-size: .9em;
					}
				}
			}
		}

		.itemExtraFields {
			border-top: none;
			


			.block {
				background-color: @blue;
				color: @white;
				margin: 0 0 0 50px;
				padding: 70px 20px;

				h3 {
					.serif;
					font-weight: 300;
					font-size: 2.7em;
					text-align: center;
				}

				>div {
					margin-top: 70px;
					margin-bottom: 70px;

					>p {
						.sans-serif;
						margin-top: 25px;
						text-transform: uppercase;
						font-size: 1em;
						padding: 0 10px;
						line-height: 1.4em;
					}

					&:before {
					    font-size: 2.4em;
					}
				}
			}
		}

		&.sidebar {
			padding: 28px;
			margin-top: 83px;
			margin-bottom: 130px;
			top: 120px;
			position: sticky;
			position: -webkit-sticky;

			@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) { 	
				position: relative!important;
				top: auto!important;
			}
		}
	}
}

/*faq*/

.faq {
    padding: 50px 0px;
    margin-top: -80px;

    @media all and (max-width: 767px) {
        margin-top: 0;
    }

    .container {
        h3 {
            font-size: 1.8em;
            border-top: 1px solid fade(@lightgrey, 100%);
            padding-top: 15px;
            color: @brown;

            @media all and (max-width: 767px) {
                font-size: 1.4em;
            }

            &:first-child {
                border: none;
            }

            &:before {
                margin-right: 15px;
                vertical-align: middle;
            }
        }

        ul {
            li {
                list-style-type: none;
            }
        }
    }
}

.socialTop {
    float: right;
    width: 80px;
	ul {
		margin: 0;
		position: absolute;
		top: 5px;
		padding: 0;
		li {
			position: relative;
			display: inline-block;
			height: 22px;
			overflow: hidden;
			img {
			.transition(all ease-in-out .2s);
			height: 45px;
				&:hover {
					margin-top: -35px;
				}
			}
		}
	}
}

.pagina-hotel-sicuro {
	a {
		color: @brown;
		text-decoration: none;

		&:hover {
			color: darken(@brown, 7%);
		}
	}
}

.vacanze-sicure {
    position:fixed;
    z-index: 4;
    cursor: pointer;
    top: 160px;
    left: 20px;
    width: 190px;
    height: 190px;
    .border-radius(50%);
    background-color: @blue;
    color: white;
    text-align: center;
    .transition;

    &:hover {
        background-color: darken(@blue, 5%);
    }

    @media all and (max-width: 1200px) {
        width: 160px;
        height: 160px;

        .text {
            font-size: .9em;
        }
    }
    @media all and (max-width: 1024px) {
        width: 140px;
        height: 140px;

        .text {
            font-size: .8em;
        }
    }
    @media all and (max-width: 768px) {
        width: 120px;
        height: 120px;

        .text {
            font-size: .75em;
        }
    }

    @media all and (max-width: 576px) {
        width: 100px;
        height: 100px;
        top: auto;
        bottom: 60px;
        left: 10px;

        .text {
            font-size: .6em;
        }
    }    

    .text {
        font-family: @serif;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-transform: uppercase;

        img {
            width: 80%;
        }       
    }   
}


#modalCovid {
	.close {
		color: white;
	}
	
	.modal-dialog {
		@media (min-width: 992px) {
			top: 15%;
		}
		@media (min-width: 1281px) {
			top: 20%;
		}

		.modal-content {
			.modal-header {
				background-image: url(/images/grand-hotel-adriatico-popup-hotel-sicuro.jpg);
				height: 150px;
				padding: 0;
				background-size: cover;
				background-position: center;
				text-align: center;

				h4.modal-title {
					color: white;
					text-shadow: 0 0 10px black;
					width: 100%;
					height: 100%;
					line-height: 130px;
					background-color: fade(black, 30%);
					text-align: center;
					.serif;
					font-size: 1.2rem;

					@media all and (max-width: 767px) {
						font-size: .9rem;
					}
				}
			}

			.modal-footer {
				display: none;
			}
		}
	}
}

#modalRistorante {
	.close {
		color: white;
	}
	
	.modal-dialog {
		.modal-content {
			.modal-header {
				background-image: url(/images/grand-hotel-adriatico-popup-ristorante.jpg);
				height: 150px;
				padding: 0;
				background-size: cover;
				background-position: center;
				text-align: center;

				h4.modal-title {
					color: white;
					text-shadow: 0 0 10px black;
					width: 100%;
					height: 100%;
					line-height: 130px;
					background-color: fade(black, 30%);
					text-align: center;
					.serif;
					font-size: 1.2rem;

					@media all and (max-width: 767px) {
						font-size: .9rem;
					}
				}
			}

			.modal-footer {
				display: none;
			}
		}
	}
}
