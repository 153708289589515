/*credits*/
.credits {
    .container {
        padding: 0!important;
		text-align: center;
        p {
            margin: 0;
            padding: 5px 0;
            font-size: .8em;
        }

        a {
            color: white;
        }
    }
}

footer {
    .logo-footer {
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid @white;
    }
    .dati-footer {
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;
        text-transform: uppercase;

        a {
            color: @white;
        }
    }
    .social {
        padding-top: 35px;
        padding-bottom: 20px;

        a {
            text-decoration: none;

            span {
                &:before {
                    font-size: 3em;
                    color: @lightgrey;
                    background-color: fade(@grey, 50%);
                    padding: 10px;
                    .transition;
                }
            }

            &:hover {
                span:before {
                    background-color: fade(@grey, 75%);
                }
            }
        }
    }

    .loghi-footer {
        opacity: 0.75;
        -webkit-filter: grayscale(30%); /* Safari 6.0 - 9.0 */
        filter: grayscale(30%);
        >p {
            margin: 0; 
            padding-top: 50px;
            padding-bottom: 50px;
            .flexbox(row);
            justify-content: space-around;

            img {
                align-self: center;
            }
        }
    }
}

#dashboard {
    @media all and (max-width: 1023px) {
        display: block;
    }

    display: none;
    position: fixed;
    z-index: 10;
    left: 0;
    bottom: 0;
    height: 50px;
    line-height: 50px;
    width: 100%;
    border-top: 1px solid fade(@black, 70%);
    box-shadow: 0 -2px 12px rgba(0,0,0,0.45);
    background-color: @black;

    .tablet & {
        height: 60px;
        line-height: 60px;
    }

        & > a {
            float: left;
            width: 33.333%;
            font-size: .6em;
            color: #fff;
            text-align: center;
            .border-radius(0);
            height: 100%;
            line-height: 1.3;
            padding-top: 2px;
            border-right: 1px solid fade(#fff, 10%);
            font-family: @sans-serif!important;

            .tablet & {
                font-size: .8em;
            }

            &:after {
                content: none;
            }

            &:last-child {
                border-right: none;
            }

            &:before {
                font-size: 1.7em;
                display: block;
                margin-right: 0;
            }
        }
}