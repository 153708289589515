@media all and (max-width: 1800px) {
	 
}
@media all and (max-width: 1680px) { 
	.navbar-default .container-fluid #navbar-id-collapse ul.navbar-nav>li>a {
	 	width: 110px;
	 	padding: 0 10px;
	 }

	 .blocco-full .flexbox .box {
	 	height: 25vw;
	 }

	 .list-blog .k2ItemsBlock .block {
	 	height: 22.5vw;

	 	.text {
	 		font-size: 1.3vw;

	 		>h2 {
	 			font-size: 2.7vw;
	 		}
	 	}
	 }

	 footer .loghi-footer>p img {
	 	max-height: 100px;
	 }
}
@media all and (max-width: 1440px) {
	.sconto-speciale {
		padding: 0 0 0 10px;
	    font-size: 0.9em;
	}
	.navbar-default .container-fluid #navbar-id-collapse ul.navbar-nav>li>a {
	 	width: 100px;
	 	font-size: .6em;
	 }
	 .navbar-default .container-fluid .btnPrenota .btn {
	 	padding: 0 10px;
 	    font-size: 1em;

 	    span {
    	    width: 140px;
 	    }
	 }

	 .blocco-full .flexbox .box {
	 	height: 30vw;
	 	width: 42.5vw;
	 }

	 .list-offers .block  {
	 	height: 30vw;

	 	.text {
 			font-size: .8em;

 			.btn {
 				margin-top: 25px;
 			}
 		 }
	 }

	 .list-blog .k2ItemsBlock .block {
	 	height: 22.5vw;

	 	.text {
	 		font-size: 1.3vw;

	 		>h2 {
	 			font-size: 2.7vw;
	 		}

	 		a.btn {
	 			margin-top: 25px;
	 		}
	 	}
	 }

	 footer .loghi-footer>p img {
	 	max-height: 90px;
	 }

	 .list-meeting, 
	 .list-room {
	 	.k2ItemsBlock {
	 		.box-container {
	 			.block {
	 				.text {
	 					font-size: .85em;
	 				}
	 			}
	 		}
	 	}
	 }
}

@media all and (max-width: 1280px) {
	.navbar-default .container-fluid .navbar-brand {
	    padding: 15px 0px 15px 10px;
		position: absolute;
		left: 0;

	    img {
	    	/*&.LowDPI {
                height: 35px;
            }

            &.HighDPI {
                display: none;
                height: 35px;
            }*/
	    }
	}
	.navbar-default .container-fluid #navbar-id-collapse ul.navbar-nav>li>a {
	 	width: 90px;
	 	font-size: .6em;
	 }
	 .navbar-default .container-fluid .btnPrenota .btn {
	 	padding: 0 10px;
 	    font-size: .6em;

 	    span {
    	    width: auto;
 	    }
	 }

	 .servizi-home .flexbox .box {
	 	span {
 		 	width: 80px;
 		 	height: 80px;

 		 	&:before {
				font-size: 1.8em;
				line-height: 80px;
 		 	}
 		 }

 		 .text {
	 	    font-size: 0.9em;
 	        padding: 0 10px;
 		 }
	 }

	 .list-blog .k2ItemsBlock .block {
	 	height: 25vw;

	 	.text {
	 		width: 90%;
	 	}
	 }

	 .list-meeting, 
	 .list-room {
	 	.k2ItemsBlock {
	 		.box-container {
	 			.block {
	 				.text {
	 					font-size: .7em;
	 				}
	 			}
	 		}
	 	}
	 }
}

@media all and (max-width: 1200px) {

	.poi-home {
    	flex-wrap: wrap;
	    
	    .box-container {
			width: 50%;

	        .block {
	            .text {
	                .wrap {
	                	h3 {
	                		font-size: 4vw; 
	                	}
	                	h2 {
	                		font-size: 5.2vw;
	                	}
	                }
	            }
	        }
	    }
	}

	.blocco-full .flexbox .box {
	 	height: 35vw;
	 	width: 45vw;

	 	.text {
	 		font-size: .9em;

	 		h3 {
 			    font-size: 2em;
	 		}

	 		a.btn {
	 			font-size: 1em;
	 			height: 40px;
	 			line-height: 40px;
	 		}
	 	}
	 }

	 .list-offers .block  {
	 	height: 40vw;
	 	
	 	.text {
 			font-size: 1em;

 			>h2 {
 				font-size: 4.2vw;
 			}

 			.btn {
 				margin-top: 30px;
 			}
 		 }
	 }

	 footer .loghi-footer>p img {
	 	max-height: 75px;
	 }

	 .list-meeting, 
	 .list-room {
	 	.k2ItemsBlock {
	 		.box-container {
	 			.block {
	 				.text {
	 					font-size: 1em;
	 				}
	 			}
	 		}
	 	}
	 }

	 .dett-offers .itemBody {

			.itemImageBlock {
		    	height: 30vw;
			}

			.itemFullText {
				padding-left: 30px;
				padding-right: 30px;

				ul li:before {
					left: 30px;
				}
			}

			.itemExtraFields .block {
				margin: 0;
			}

			&.sidebar {
				margin-top: 0;
			}
		}
}

@media all and (max-width: 1100px) {
	.blocchi-testo.no-img .block {
		width: 50vw;
	}

	.blocchi-video .block .text {
		width: 45vw;
		padding-top: 20px;
    	padding-bottom: 20px;
    	font-size: .8em;
	}
}

@media all and (max-width: 1023px) {
	.h1 h1 {
		width: 100%;
		line-height: 32px;
		height: 100%;
	}
	.go-down {
		margin-top: -82px;
		right: 130px;
		left: auto; 
	}

	.navbar-default .container-fluid {
		padding-left: 0!important;

		.navbar-brand {
			padding: 8px 0 15px 10px;
		}

		#navbar-id-collapse {
			width: 100%;
			padding-left: 0!important;

			ul.navbar-nav>li {
				margin-top: 3px;

				>a {
					width: auto;
				}

				.dropdown-menu {
					width: 100%;
					position: relative;
				}
			}
		}
	}

	.menu-top {
	    margin: 0;

		>li {
		    display: inline-block;
		}
	}

	body.tablet {
		.dett-offers .itemBody {
			.itemImageBlock {
		    	height: 40vw;
			}

			.itemFullText {
				padding-left: 30px;
				padding-right: 30px;

				ul li:before {
					left: 30px;
				}
			}

			.itemExtraFields .block {
				margin: 0;
			}
		}
	}

	body.tablet,
	body.mobile {
		.blocco-full {
			.intro {
				width: 80%;
			}
			.flexbox .box {
				height: auto;
			}
		}

		.list-blog .k2ItemsBlock {
			.flexbox(column);

			.block {
				width: 80vw;
				height: 35vw;
			    margin: 0 auto;

			    &:nth-child(2) {
			    	margin: 15px auto;
			    }

			    .text {
			    	font-size: 1em;

			    	>h2 {
			    		font-size: 1.6em;
			    		margin-bottom: 10px;
			    	}
			    }
			}
		}

		.review .text {
			margin: 0 5%;
		}

		.navbar-default .container-fluid #navbar-id-collapse {
		    overflow-x: hidden;
			overflow-y: scroll;
			height: 100vh;
			
			ul.navbar-nav {
				
				margin-bottom: 200px;

				>li {
					>a {
						font-size: 1em;
					}

					&.dropdown>a:after {
					    right: 15px;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;	
    					border-top: 10px solid #fff;
					}

					.dropdown-menu {
						font-size: .9em;

						>li {
							border-bottom: 1px solid fade(@white, 20%);

							&:last-child {
								border-bottom: none;
							}

							>a {
								height: 50px;
	    						line-height: 55px;
							}
						}
					}
				}
			}
		}

		footer {
			.dati-footer {
				font-size: 0.8em;
			}

			.logo-footer {
				padding-top: 25px;
				padding-bottom: 25px;
			}
		}

		.list-meeting .k2ItemsBlock .box-container .block .text .wrap h2, .list-room .k2ItemsBlock .box-container .block .text .wrap {
			
			h3 {
				font-size: 1.2em;
			}

			h2 {
				font-size: 2em;
			}
		}
		
		.swiper-container-gallery-rooms {
			margin-top: 0;
			padding: 0;
		}

		.swiper-container-gallery-rooms,
		.swiper-container-gallery-rooms .swiper-wrapper .swiper-slide a span {
			height: 70vmin;
		}

		.calltoaction p {
			width: 95%;
		    margin: 15px -2px;

		    a {
		    	font-size: 1em;
		    }

			&:nth-child(2) {
				margin-left: 0;
			}
		}

		.marker-poi {
			margin-bottom: 0;

			> tbody {
				>tr {
					>td {
						line-height: 2;
					}
				}
			}
		}

		.blocchi-testo .block {
			.flexbox(column);

			.background-cover {
			    min-height: 50vmin;
			}

			.text {
				width: 90vw;
				padding-top: 20px;
				padding-bottom: 20px;
			}
		}

		.blocchi-testo.no-img .block .text {
			padding-left: 30px;
			padding-right: 30px;
		} 

		.how-reach-us>.tab-control ul.nav.nav-stacked li {
			height: 70px;

			a {
			    border-right: 1px solid rgba(45,132,176,.3);
			}
		}

		.blocchi-testo.no-img {
			.flexbox(column);
		}

		.service-room.green.sostenibilita .text .container-fluid ul {
		    padding: 50px;
    		width: 90%;
		    margin: 0 auto;
		}

		.service-room .text .container-fluid {
			.flexbox(column);

			ul {
				padding: 40px 70px;
				width: 90%;
				margin: 0 auto;

				&:last-child {
				    margin-top: 30px!important;
				}
			}
		}

		.blocchi-video .block {
			.flexbox(column);

			.text {
				width: 90vw;
			}

			.video {
			    height: 60vmin;
			}
		}

		.list-offers .block {
			&:nth-child(1n+2) {
				border-left: none;
				border-right: none;
			}

			border-bottom: none;
			margin-bottom: 20px;
		}

		.dett-offers .itemBody {
			.itemImageBlock {
		    	height: 40vw;
			}

			.itemExtraFields .block {
				margin: 0;
			}
		}
	}
	
	/*MOBILE*/
	
	body.mobile {
		
		.h1 {
			width: 50%;

			>h1 {
				line-height: 35px;
				width: 100%;
				height: 100%;
				font-size: 10px;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		.navbar-default .container-fluid #navbar-id-collapse ul.navbar-nav>li>a {
			height: 50px;
			line-height: 50px;
		}

		.blocco-full {
			.intro {
				width: 90%;

				>h2 {
					font-size: 2em;
				}
			}

			.flexbox {
				height: 90vw;
				display: block!important;

				.box {
					height: 90vw;
					width: 100%;

					&:first-child {
					    margin-right: 0;
					    margin-bottom: 30px;
					}
				}
			}
		}

		.list-offers .block {
			height: 80vmin;

			.text {
				width: 100%;
				font-size: .85em;
				>h2 {
					font-size: 2em;
				}
			}
		}

		.list-blog {
			>h3 {
				font-size: 2em;
				width: 98%;
				margin: 0 2%;
			}

			>p {
				width: 90vw;
				margin: 0 auto 50px;
			}

			.k2ItemsBlock .block {
				width: 90vw;
				height: 90vmin;
			}
		}

		.servizi-home>h2 {
			font-size: 2em;
			width: 98%;
	    	margin: 0 2% 20px;
		}

		.loghi-footer {
			display: none;
		}

		.langmenu {
			margin-left: 1px;

			button {
				padding: 0 6px;
				height: 23px;
				line-height: 17px;
				font-size: 0.5em;

				span {
					line-height: 19px;
					margin-left: 0;
				}
			}
		}
		.menu-top {
			>li {
				padding: 5px 1px;

				>a {
				    font-size: 0.5em;
				    padding-top: 7px;
				    padding-bottom: 4px;
				}
			}
		}

		.review {
			.text {
				blockquote {
					font-size: .6em;
					padding: 10px 50px;

					h2 {
						margin-bottom: 20px;
					}
				}
			}
		}

		.credits .container p {
			font-size: .6em;
		}

		footer {
			padding-bottom: 49px;
		}

		.list-meeting, .list-room {
			>h2 {
				font-size: 2em;
				width: 90%;
				margin: 0 auto 40px;
			}

			.k2ItemsBlock {
				.flexbox(column);

				.box-container {
					.flexbox(column);

					.block {
						width: 100%;

						.text {
							.wrap {
								h3 {
									font-size: 1em;
									margin-bottom: 10px;
								}
								h2 {
									margin-top: 10px;
									font-size: 1.5em;
								}
								a.btn {
									margin-top: 0;
									font-size: 1.2em;
									height: 40px;
								    line-height: 40px;
								}
							}
						}
					}

					.block-add {
						display: none;
					}
				}
			}
		}

		.how-reach-us>.tab-content .tab-pane {
			padding: 25px 0;

			>.tab-control ul.nav.nav-stacked li a {
				padding: 0 15px;
			}
		}

		.blocchi-testo.no-img .block {
			width: 100vw;
		}


		.list-service-mobile {
	        margin-top: 0px;

	        h3 {
	        	.sans-serif;
	            outline: none;
	            border: 3px solid fade(@brown, 100%);
	            font-size: .9em;
	            padding: 15px 10px;
	            padding-right: 15vw;
	            margin: 15px auto;
	            position: relative;
	            .transition(all ease-in-out .3s);
	            color: @darkgrey;
	            background-color: fade(@brown, 10%);
	            font-weight: normal;

	            &:before {
	                position: absolute;
	                right: 7px;
	                top: 0;
	                line-height: 1.5;
	                vertical-align: middle;
	                font-size: 8vmin;
	                .transition(all ease-in-out .3s);
	                color: @brown;

	                @media all and (orientation: landscape) {
	                    .mobile & {
	                        font-size: 8vh;
	                    }
	                }
	            }

	            &.ui-state-active {
	                border: 3px solid fade(@brown, 100%);
	                border-bottom: 3px solid fade(@brown, 100%);
	                background-color: @brown;
	                color: @white;

	                &:before {
	                    color: @white;
	                    .transform(rotate(540deg));
	                }
	            }
	        }

	        div {
	            padding: 30px;
	            background-color: transparent;
	            margin-top: -15px;
	            border: 3px solid @brown;
	            background-color: fade(@brown, 10%);
	            color: @darkgrey;
	            border-top: none;

	            &:last-child {
	                margin-bottom: 30px;
	            }

	            ul {
	                text-align: left;
	                margin: 20px auto;

	                li {
	                    position: relative;
	                    padding-left: 20px;

	                    &:before {
	                        font-size: .8em;
	                        position: absolute;
	                        left: 0;
	                        top: 50%;
	                        line-height: 0;
	                    }
	                }
	            }

	            @media all and (max-width: 767px) {
	                a.btn {
	                    font-size: .7em;
	                }
	            }
	        }
	    }

	    .blocchi-testo.ristorante .block:last-child .text {
	    	width: 90vw;
	    }

	    .service-room .text .container-fluid ul {
	    	padding: 40px 50px;
	    }


	    .blocchi-testo .block .text {
	    	width: 100vw;
	    }

	    .service-room.green.sostenibilita .text .container-fluid {
	    	
	    	h2 {
	    		font-size: 2em;
	    		margin-bottom: 10px;
	    	}
	    	
	    	ul {
				padding: 20px;
				width: 95%;
	    	}
	    }

	    .service-room.green.differenza .text .container-fluid {
	    	
	    	h2 {
	    		font-size: 2em;
	    		margin-bottom: 20px;
	    	}
	    	
	    	ul {
				padding: 35px;
				width: 95%;
	    	}
	    }

	    .blocco-full .flexbox {
	    	height: auto;

	    	.box {
	    		height: auto;
	    	}
	    }

	    .list-offers>h2 {
	    	font-size: 2em;
	    	margin-top: 0;
	    }

	    .dett-offers .itemBody {
			.itemImageBlock {
		    	height: 50vw;
			}

			.itemFullText {
				padding-left: 0px;
				padding-right: 0px;

				ul li:before {
					left: 0px;
				}
			}

			.itemExtraFields .block {
				margin: 0;

				a.btn {
				    padding: 0 30px;
				}
			}

			&.sidebar {
				padding: 0;
			}
		}

		.dett-blog .articolo-blog .testo-articolo-blog p {
			padding: 0;
		}

		.sidebarBlog {
			.background-cover {
				height: 20vw;
				width: 30vw;
			}

			&.col-sm-8 .k2ItemsBlock {
				.container-full {
					margin: 0;
				}
			}
		}

		.faq {
			padding-top: 0;
		}

		.blocco-full.business .flexbox .box {
			padding: 20px;

			.text {
				position: relative;
				top: auto;
				left: auto;
				.transform(translate(0, 0));
			}
		}

		@media (orientation: landscape) {
			#dashboard {
				display: none!important;
			}
		}
	}

}

.mobile .socialTop {
    display: none;
}