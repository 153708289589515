header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 5;

    .headerTop {
        height: 33px;
        width: 100%;
        z-index: 1;
        position: relative;
    }
}

/*menu top*/
.menu-top {
    float: right;

    >li {
        padding: 5px;

        >a {
            background-color: @grey; 
            border: 1px solid @grey;
            color: @white;
            padding-top: 5px;
            padding-bottom: 1px;
            font-size: .7em;
            text-transform: uppercase;  
            line-height: 1em;
            .transition;

            &:hover {
                background-color: @white;
                color: @grey;
            }
        }
    }
}

/*menu lingua header top*/
.langmenu {
    margin-left: 5px;
    float: right;

    @media all and (max-width: 767px) {
        right: 0;
    }

    button {
        background-color: @grey;
        border: 1px solid @grey;
        color: @white;
        height: 22px;
        line-height: 18px;
        font-size: 0.8em;
        text-transform: uppercase;
        .transition;

        &:hover {
            background-color: @white;
            color: @grey;
        }

        @media all and (max-width: 767px) {
            padding: 0 10px;
        }

        span {
            margin-left: 10px;
            line-height: 32px;

            .mobile &,
            .tablet & {
                font-size: .8em;

                &:before {
                    vertical-align: sub;
                }
            }

            &:before {
                font-size: 1.6em;
                vertical-align: middle;
            }
        }
    }

    ul {
        margin-left: -8px!important;
        border-top: none;
        .border-radius(0 0 3px 3px);
        min-width: auto;
        background-color: #222;

        li {
            border-bottom: 1px solid #000;
            margin-left: 0!important;
            margin-right: 0!important;

            &:last-child {
                border: none;
            }

            a {
                text-transform: uppercase;
                color: #fff;

                &:hover {
                    color: #fff;
                    background-color: #666;
                }

                img {
                    border: 1px solid #ecebeb;
                }
            }
        }
    }
}

/*H1*/
.col-sm-12.h1 {
    margin: 0;
    padding: 0;
    float: left;
}

.h1 h1 {
    font-size: 12px;
    z-index: 6;
    color: @grey;
    left: 0;
    text-align: left;
    top: 0;
    margin: 0 0 0 10px;
    padding: 0;
    line-height: 33px;
    text-transform: uppercase;

    @media all and (max-width: 768px){
        width: 80%;
        overflow: hidden;
        line-height: 12px;
        height: 12px;
    }
}

/*MENU*/
.navbar-default {
    margin-bottom: 10px;
    background-color: fade(@black, 50%);
    .transition;
    
    .container-fluid {
        margin: 0 auto;
        padding-right: 0;
        .box-shadow(0px 0px 1px 0px rgba(0,0,0,0.3));

        .navbar-brand {
            img {
                &.LowDPI {
                    height: 45px;
                }

                &.HighDPI {
                    display: none;
                    height: 45px;
                }

                @media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
                only screen and (-o-min-device-pixel-ratio: 13/10),
                only screen and (min-resolution: 120dpi)
                {
                    &.HighDPI {
                        display: block;
                    }
                    
                    &.LowDPI {
                        display: none;
                    }
                }
            }

        }

        #navbar-id-collapse {
            margin: 0 20px;
            margin-right: 0;
            padding-right: 0;

            ul.navbar-nav {
                margin: 0 auto;
                width: 100%;
                .sans-serif;

                >li {
                    position: relative;
                    margin-left: 3px; 
                    margin-right: 3px; 

                    &.active,
                    &:hover {
                    }

                    &.dropdown {
                        >a:after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            margin-top: -4px;
                            right: 4px;
                            line-height: 0;
                            width: 0;
                            height: 0;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-top: 7px solid @white;
                        }
                    }

                    >a {
                        font-size: .7em;
                        color: fade(@white, 100%);
                        background-color: @brown;
                        padding: 0 20px;
                        height: 75px;
                        width: 120px;
                        text-transform: uppercase;
                        .flexbox(column);
                        justify-content: center;
                        text-align: center;
                    }

                    .dropdown-menu {
                        background-color: fade(@black, 70%);
                        .border-radius(0 0 3px 3px);
                        font-size: .7em;
                        padding-top: 0;
                        padding-bottom: 0;

                        >li {
                            &.active {
                                a {
                                    background-color: fade(@black, 25%);
                                }
                            }
                            
                            &:hover {
                                a {
                                    background-color: fade(@blue, 75%);
                                }
                            }

                            >a {
                                color: @white;
                                padding: 0px 20px;
                                height: 30px;
                                line-height: 35px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }

        .btnPrenota {
            margin-left: 3px;

            @media all and (max-width: 1023px) {
                display: none;
            }

            .btn {
                padding: 0;
                height: 75px;
                line-height: 75px;
                font-size: 1.4em;
                border: none;

                &:hover {
                    border: none!important;
                    background-color: darken(@white, 2%)!important;
                }

                span {
                    width: 300px;
                    margin: 0 auto;
                    text-align: center;

                    &.chiuso {
                        display: block;
                    }
                    &.aperto {
                        display: none;
                    }
                }
            }

            &.aperto {
                
                .btn {
                    span.chiuso {                            
                        display: none;
                    }
                    span.aperto {
                        display: block;
                    }
                }
            }
        }
    }
}

&.affix {
    .navbar-default {
        background-color: fade(@black, 100%);
    }
}


/*BACKGROUND*/
.swiper-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;



       .swiper-button {
            width: 130px;
            position: absolute;
            top: 80vh;
            margin-top: -60px;
            right:15px;
            z-index: 1;

            .swiper-button-next,
            .swiper-button-prev {
                background-color: fade(@brown, 50%);
                color: fade(@white, 100%);
                font-size: 2em;
                z-index: 5;
                font-family: "icomoon";
                width: 65px;
                height: 65px;
                line-height: 65px;
                .transition;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    .transform(translate(-50%, -50%));
                }

                &:hover {
                    background-color: fade(@brown, 900%);
                }
            }







        }	
	

	
	
	
    &-background {
        height: 100vh;

		.swiper-button {
            top: 100vh;
                .swiper-button-next {
                    right: 0;

                    &:before {
                       content: "\e91d";
                    }
                }
                .swiper-button-prev {
                    left: 0;

                    &:before {
                        content: "\e91c";
                    }
                }
		}			
		
        .swiper-wrapper {
            .swiper-slide {
                height: 100vh;
                position: relative;

                .claim {
                    position: absolute;
                    color: @white;
                    text-align: center;
                    top: 50%;
                    left: 50%;
                    width: 60%;
                    .transform(translate(-50%, -50%));
                    font-size: 1em;

                    @media all and (max-width: 1680px) {
                        font-size: .9em;
                    }
                    @media all and (max-width: 1480px) {
                        font-size: .7em;
                    }
                    @media all and (max-width: 1200px) {
                        font-size: .6em;
                    }

                    p,
                    h3,
                    h2 {
                        text-shadow: 1px 1px 1px @black, 0 0 40px @black;
                    }

                    p {
                        font-size: 1.2em;
                    }

                    h2 {
                        .sans-serif;
                        text-transform: uppercase;
                        font-size: 2em;
                        margin: 0;
                        margin-bottom: 30px;
                    }

                    .btn {
                        margin-top: 50px;
                        text-shadow: none;
                        height: 50px;
                        line-height: 54px;
                        background-color: fade(@white, 75%);

                        &:hover {
                            background-color: fade(@white, 0%);
                        }
                    }
                }
            }
        }

        .tablet & {
            height: 100vh!important;
        }

        &-custom {
            height: 80vh!important;

            .swiper-button {
                        .swiper-button-next {
                            right: 0;

                            &:before {
                               content: "\e91d";
                            }
                        }
                        .swiper-button-prev {
                            left: 0;

                            &:before {
                                content: "\e91c";
                            }
                        }
                    }           

            .tablet & {
                height: 70vh!important;
            }

            .mobile & {
                height: 45vh!important;
            }

            .swiper-wrapper {
                .swiper-slide {
                    position: relative;

                    >span.opacity {
                        .opacity(.75);
                    }

                    .text {
                        position: absolute;
                        color: @white;
                        text-align: center;
                        width: 100%;
                        top: 50%;
                        left: 50%;
                        .transform(translate(-50%, -50%));

                        .tablet & {
                            top: 65%;
                        }

                        p,
                        h3,
                        h2 {
                            text-shadow: 1px 1px 1px #4c4c4c, 0 0 40px #ababab;
                        }

                        p {
                            font-size: 2em;

                            @media all and (max-width: 767px) {
                                font-size: 1em;
                            }
                        }

                        h3 {
                            .serif;
                            font-size: 4em;

                            @media all and (max-width: 767px) {
                                font-size: 2.6em;
                            }
                        }
                        h2 {
                            font-size: 4.2em;
                            line-height: 1;
                            margin-top: 0;
                        }

                        .btn {
                            text-shadow: none;
                        }
                    }
                }
            }
        }
/*
        .swiper-button {
            width: 130px;
            position: absolute;
            top: 100vh;
            margin-top: -60px;
            right:15px;
            z-index: 1;

            .swiper-button-next,
            .swiper-button-prev {
                background-color: fade(@brown, 50%);
                color: fade(@white, 100%);
                font-size: 2em;
                z-index: 5;
                font-family: "icomoon";
                width: 65px;
                height: 65px;
                line-height: 65px;
                .transition;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    .transform(translate(-50%, -50%));
                }

                &:hover {
                    background-color: fade(@brown, 900%);
                }
            }

            .swiper-button-next {
                right: 0;

                &:before {
                   content: "\e91d";
                }
            }
            .swiper-button-prev {
                left: 0;

                &:before {
                    content: "\e91c";
                }
            }
        }
*/		
    }



    /*swiper full pag ristorante*/
    &-block-full {
        height: 650px;

        @media all and (max-width: 767px) {
            height: 250px;
        }

        @media all and (max-width: 1280px) {
            height: 370px;
        }

        .swiper-wrapper {
            .swiper-slide {
                height: 650px;
                position: relative;

                @media all and (max-width: 767px) {
                    height: 250px;
                }
            }
        }
    }
}

.sconto-speciale {
    position: absolute;
    z-index: 1;
    top: 100vh;
    margin-top: -80px;
    left: 15px;
    height: 65px;
    line-height: 70px;
    background-color: fade(@blue, 50%);
    color: @white;
    font-weight: 300;
    padding: 0 0 0 20px;
    font-size: 1.3em;

    >p {
        margin: 0;
        padding: 0;
    }

    a {
        height: 65px;
        line-height: 70px;
        background-color: @black;
        color: @white;
        padding-right: 30px;
        padding-left: 20px;
        display: inline-block;
        box-sizing:border-box;
        text-decoration: none;
        .transition;

        &:hover {
            background-color: @brown;
        }
    }
}

.go-down {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 100vh;
    margin-top: -80px;
    left: 50%;
    .transform(translateX(-50%));
    height: 65px;
    width: 65px;
    background-color: fade(@blue, 50%);
    .transition(all ease-in-out .3s);

    &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        .transform(translate(-50%, -50%));
        color: @white;
    }

    &:hover {
        background-color: darken(fade(@blue, 75%), 20%);
    }
}