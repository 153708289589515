/* --- CHRONOFORM ----------------------------------------------------------- */
.chrono_credits { display: none; }

/* --- BANNER COOKIES ------------------------------------------------------- */
#banner-cookies {
    position: fixed;
    right: 0;
    bottom: -100%;
    width: 100%;
    margin-bottom: 0;
    max-width: 600px;
    z-index: 10;
    font-size: .9rem;
    line-height: 1;
    color: #fff;
    background-color: #222;
    .border-radius(0);

    .mobile & {
        margin-bottom: 50px;
    }

    .btn {
        background-color: #555;
    }

    .keyframes(Cookies;{
        from {bottom: -100%;}
        to {bottom: 0;}
    });

    .animation(Cookies 3s forwards 2s ease-out);
}


/* --- CHRONOFORM ----------------------------------------------------------- */
.chronoforms6_credits { display: none; }
.mobile & {
    .g-recaptcha {
        .transform(scale(.77));
    }
}

.form-contatti {

    form {
        div {
            input,
            textarea#Richiesta {
                background-color: fade(@lightgrey, 50%)!important;
                outline: none!important;
                border: none!important;
                border-bottom: 3px solid fade(@blue, 100%)!important;
                vertical-align: top!important;
                color: @darkgrey!important;
                padding: 5px!important;
                display: block!important;
                border-radius: 0px!important;
                font-size: 1.2em!important;
                padding-left: 10px!important;

                &:focus {
                    border-bottom: 3px solid darken(@blue, 5%)!important;
                }
            }
            input {
                height: 40px!important;
            }

            label {
                display: none!important;
            }

            &.error {
                input,
                textarea#Richiesta {
                    border-color: #E0B4B4!important;
                }
            }

            .ui.checkbox input.hidden+label {
                display: block!important;
                color: @black;

                a {
                    color: @black;
                    font-weight: bold;
                }
            }
        }
        #btnInvia {
            color: @white!important;
            border: 3px solid @brown!important;
            background: @brown!important;
            .border-radius(0);
            margin-top: 20px;
            font-size: 1.2em;
        }
    }

    .ui.checkbox {
        .box,
        label {
            &:before {
                border: none;
                background: @lightgrey!important;
                .border-radius(0);
                .transition;
            }

            &:after {
                color: @white!important;
            }

            &:hover {
                &:before {
                    background: darken(@lightgrey, 10%)!important;
                }
            }
        }

        &.checked {
            .box,
            label {
                &:before {
                    background: @blue!important;
                }
            }            
        }
    }
}